import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addLicense } from '../../../redux/license';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Modal,
  NativeSelect,
  Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import KeyIcon from '@mui/icons-material/Key';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { styled, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { enqueueSnackbar } from 'notistack';
import { GROUP_SERVER, License_SERVER } from '../../../components/Config';
import { useForm } from 'react-hook-form';
import { Parser } from '@json2csv/plainjs';
import { string as stringFormatter } from '@json2csv/formatters';
import { downloadLicenseFile } from '../../../utils/util';
import axios from 'axios';
axios.defaults.withCredentials = true;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'rgb(253,237,237)',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const customerErrorModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const theme = createTheme({
  main: 'rgba(0, 0, 0, 0.6)',
  orange: orange[500],
});

const CustomFormControl = styled(FormControl)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const CustomButton = styled(Button)({
  textTransform: 'none',
});

const CustomCheckbox = styled(Checkbox)({
  '&.Mui-checked': {
    color: orange[600],
  },
});

function LicenseComponent(props) {
  const [swList, setSwList] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMsgModalOpen, setErrorMsgModalOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const dispatch = useDispatch();

  const handleClose = () => setOpen(false);
  const handleErrorMsgModalClose = () => {
    setErrorMsg('');
    setErrorMsgModalOpen(false);
  };
  const user = useSelector((state) => state.user);
  // const license = useSelector((state) => state.license);

  const formCSVLicense = (data, downloadOption = true) => {
    // customer field 공백일때 alert

    if (props.customer === '') {
      enqueueSnackbar('Please enter a customer name.', {
        variant: 'warning',
        autoHideDuration: 3000,
      });
    } else if (data.licenseCode === '' || data.licenseCode === undefined) {
      enqueueSnackbar('License code is empty.', {
        variant: 'info',
        autoHideDuration: 3000,
      });
    } else {
      data.customer = props.customer;
      let index = swList.findIndex((row) => row._id === data.sw);
      data.sw = swList[index].productname;
      // console.log(data);

      // MyVet Equine SW 예외처리
      if (data.sw === 'MyVet Equine SW') {
        let output = '[LICENSE_INFO]\nACTIVATION_CODE=';
        output += data.licenseCode;

        let filename = 'MyVetEquineSW.lic';

        let blob = new Blob([output], { type: 'text' });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, filename);
        } else {
          let link = document.createElement('a');

          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
        return;
      } else {
        // let fields = ['group', 'customer', 'software', 'optionLicense', 'requestCode', 'licenseKey'];
        // let fieldNames = ['Agency', 'Customer', 'S/W', 'Option', 'Request Code', 'License Key'];
        let exportData = {};
        exportData.Agency = user.userData.group.name;
        exportData.Customer = data.customer;
        exportData['S/W'] = data.sw;
        let optionLicenseString = '';

        for (let i = 0; i < data.options.length; i++) {
          // 활성화된 옵션 확인
          if (data[data.options[i].name]) {
            if (optionLicenseString === '') {
              optionLicenseString = data.options[i].name;
            } else {
              optionLicenseString += ', ' + data.options[i].name;
            }
          }
        }

        if (optionLicenseString === '') {
          optionLicenseString = 'none';
        }

        exportData.Option = optionLicenseString;
        exportData['Request Code'] = data.requestCode;
        exportData['License Key'] = data.licenseCode;
        // console.log(exportData);
        const opts = {
          data: exportData,
          delimiter: '\t',
          formatters: {
            // Never use quotes
            string: stringFormatter({ quote: '' }),
          },
        };
        const parser = new Parser(opts);
        const csv = parser.parse(exportData);

        // console.log(csv);
        dispatch(addLicense(exportData));
        if (downloadOption) {
          downloadLicenseFile(csv);
        }
      }
    }
  };

  const onSubmit = async (data, e) => {
    try {
      let dataToSubmit = data;
      let specialRule = /([`~!@#$%^&*()-_=+?.,?'\s])/gi;
      let wordRegex = /^[\p{L}0-9]+$/u;
      let copyCustomer = props.customer;
      let replaceCustomer = copyCustomer.replace(specialRule, '');

      // customer field 공백일때, 공백문자열일떄 alert
      if (props.customer === '' || /^\s*$/.test(props.customer)) {
        setErrorMsg('Please enter a customer name.');
        setErrorMsgModalOpen(true);
      } else if (replaceCustomer === '' ? false : !wordRegex.test(replaceCustomer)) {
        props.customerValidation(true);
        //customer textfield setFocus
        props.customerRef.current.focus();
      } else if (dataToSubmit.requestCode === '') {
        setErrorMsg('Please enter a request code.');
        setErrorMsgModalOpen(true);
      } else {
        dataToSubmit.userId = user.userData._id;
        dataToSubmit.customer = props.customer;
        dataToSubmit.group = user.userData.group;
        dataToSubmit.licenseCode = '';

        axios.post(`${process.env.REACT_APP_SERVER}${License_SERVER}`, dataToSubmit).then((res) => {
          // console.log(res.data.licenseKey);
          if (res.data.error) {
            setErrorMsg(res.data.error);
            setErrorMsgModalOpen(true);
          } else {
            setValue('licenseCode', res.data.licenseKey);
            //라이선스 발급 완료 시 다운로드 받을 정보로 정렬하여 redux 저장
            //formCSVLicense
            //@data
            //@txt download 함수실행 유무
            data.licenseCode = res.data.licenseKey;
            handleSubmit(formCSVLicense(data, false));
          }
        });
      }
    } catch (e) {
      // handle your error
      console.log(e);
    }
  };

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      customer: props.customer,
      sw: '',
      group: '',
      options: '',
      requestCode: '',
      licenseCode: '',
      rent: '',
      type: 'New',
    },
  });

  //component 최초 loading시 option count default setting
  useEffect(() => {
    // setInitialValues({ ...INITIAL_VALUES });

    //sw List DB로 부터 가져오기
    if (user.userData.hasOwnProperty('isAuth')) {
      axios
        .get(`${process.env.REACT_APP_SERVER}${License_SERVER}/products`)
        .then((res) => {
          // console.log(res.data);
          if (!res.data.hasOwnProperty('isAuth')) {
            setSwList(res.data);
            setValue('sw', res.data[0]._id);
            props.setSelectSw(res.data[0]._id);

            if (user.userData.group !== undefined) {
              let groupId = user.userData.group._id;

              //sw의 group type 조회 - groupCount name 얻기위함
              axios
                .get(`${process.env.REACT_APP_SERVER}${License_SERVER}/producttype/${res.data[0]._id}`)
                .then((groupNameRes) => {
                  let groupTypeName = groupNameRes.data[0].groupType.groupTypeName;

                  // groupCount 조회
                  axios.get(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}/group/${groupId}`).then((groupCountRes) => {
                    //option 동적 생성, 유료 option count 추가
                    let optionList = [];

                    if (res.data[0].options) {
                      for (let i = 0; i < res.data[0].options.length; i++) {
                        let option = res.data[0].options[i];

                        if (option.optionStatus) {
                          optionList.push(option);
                          setValue(option.optionName, false);

                          if (option.optionStatus) {
                            if (option.optionType === 'paid') {
                              let findOptionIdx = optionList.findIndex((item) => item.optionName === option.optionName);

                              if (findOptionIdx !== -1)
                                groupCountRes.data[groupTypeName].options[option.optionName].available !== undefined
                                  ? (optionList[findOptionIdx]['available'] =
                                      groupCountRes.data[groupTypeName].options[option.optionName].available)
                                  : (optionList[findOptionIdx]['available'] = 0);
                            }
                          }
                        }
                      }
                    }

                    setValue('options', optionList);
                  });
                });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (watch('Rent License')) {
      setValue('rent', '1 M');
      setOpen(true);
      // enqueueSnackbar(
      //   'Rent-license is for rental dealer. If you want to issue SW permanently, please uncheck this option.',
      //   {
      //     variant: 'error',
      //     autoHideDuration: 3000,
      //   }
      // );
    } else {
      setValue('rent', '');
    }
  }, [watch('Rent License')]);

  return (
    <div className="LicenseComponent">
      <Card
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        elevation={1}
        sx={{ padding: '16px', marginTop: '16px' }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3} md={3}>
            <Typography
              variant="h4"
              fontWeight={500}
              sx={{
                textAlign: 'left',
                float: 'left',
                color: 'rgba(197,197,197,1.00)',
              }}
            >
              {props.name}
            </Typography>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ marginLeft: '-1px' }} />
          <Grid item xs={12} sm={9} md={9} container spacing={2}>
            <Grid item xs={12}>
              <CustomFormControl id="swList" fullWidth>
                <InputLabel shrink variant="standard" htmlFor="sw">
                  SW
                </InputLabel>
                {swList && (
                  <NativeSelect
                    id="sw"
                    label="sw"
                    {...register('sw')}
                    disabled={getValues('licenseCode') !== ''}
                    onChange={(e) => {
                      // formik.handleChange(e);
                      // sw 선택 시 Option 동적 생성
                      if (e.target.value) {
                        let options = swList.find((item) => item._id === e.target.value);

                        if (options) {
                          if (user.userData.group !== undefined) {
                            let groupId = user.userData.group._id;

                            //sw의 group type 조회 - groupCount name 얻기위함
                            axios
                              .get(`${process.env.REACT_APP_SERVER}${License_SERVER}/producttype/${e.target.value}`)
                              .then((res) => {
                                let groupTypeName = res.data[0].groupType.groupTypeName;

                                // groupCount 조회
                                axios
                                  .get(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}/group/${groupId}`)
                                  .then((res) => {
                                    //option 동적 생성, 유료 option count 추가
                                    let optionList = [];
                                    if (options.options) {
                                      for (let i = 0; i < options.options.length; i++) {
                                        let option = options.options[i];

                                        if (option.optionStatus) {
                                          optionList.push(option);
                                          setValue(option.optionName, false);

                                          if (option.optionStatus) {
                                            if (option.optionType === 'paid') {
                                              let findOptionIdx = optionList.findIndex(
                                                (item) => item.optionName === option.optionName
                                              );

                                              if (findOptionIdx !== -1)
                                                res.data[groupTypeName].options[option.optionName].available !==
                                                undefined
                                                  ? (optionList[findOptionIdx]['available'] =
                                                      res.data[groupTypeName].options[option.optionName].available)
                                                  : (optionList[findOptionIdx]['available'] = 0);
                                            }
                                          }
                                        }
                                      }
                                    }

                                    setValue('options', optionList);

                                    props.setSelectSw(e.target.value);
                                  });
                              });
                          }
                        }
                      }
                    }}
                  >
                    {swList &&
                      swList.map((v, i) => {
                        return (
                          <option key={v._id} value={v._id}>
                            {v.name}
                          </option>
                        );
                      })}
                  </NativeSelect>
                )}
              </CustomFormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography>Option</Typography>
              {watch('options') &&
                watch('options').map((v, i) => {
                  return (
                    <div key={v.optionName}>
                      <FormControlLabel
                        disabled={getValues('licenseCode') !== '' || v.available === 0}
                        control={
                          <CustomCheckbox {...register(`${v.optionName}`)} checked={Boolean(watch(v.optionName))} />
                        }
                        label={v.optionName}
                      />
                      {v.optionName === 'Rent License' && (
                        <NativeSelect
                          id="rent"
                          label="rent"
                          {...register('rent')}
                          value={watch('rent')}
                          disabled={!Boolean(watch(v.optionName))}
                        >
                          <option key={0} value={'1 M'}>
                            1 Month
                          </option>
                          <option key={1} value={'2 M'}>
                            2 Months
                          </option>
                          <option key={2} value={'3 M'}>
                            3 Months
                          </option>
                          <option key={6} value={'6 M'}>
                            6 Months
                          </option>
                        </NativeSelect>
                      )}
                      <div style={{ float: 'right' }}>{v.optionType === 'paid' ? `(${v.available})` : ''}</div>
                    </div>
                  );
                })}
            </Grid>
            <Grid item xs={12}>
              <CustomFormControl fullWidth variant="standard">
                <InputLabel htmlFor="requestCode">Request Code</InputLabel>
                <Input
                  id="requestCode"
                  endAdornment={
                    <InputAdornment position="end">
                      <CustomButton
                        type="submit"
                        color="error"
                        variant="contained"
                        size="small"
                        sx={{ marginBottom: '4px' }}
                        startIcon={<KeyIcon />}
                        // disabled={getValues('licenseCode') !== ''}
                      >
                        License
                      </CustomButton>
                    </InputAdornment>
                  }
                  label="requestCode"
                  {...register('requestCode')}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      setValue('licenseCode', '');
                    }
                  }}
                />
              </CustomFormControl>
            </Grid>
            <Grid item xs={12}>
              <CustomFormControl fullWidth variant="standard">
                <InputLabel htmlFor="licenseCode">License Code</InputLabel>
                <Input
                  readOnly
                  id="licenseCode"
                  label="LicenseCode"
                  {...register('licenseCode')}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  value={watch('licenseCode') || ''}
                  sx={{ backgroundColor: 'rgba(180, 180, 180,0.3)' }}
                  endAdornment={
                    <InputAdornment position="end">
                      <CustomButton
                        color="secondary"
                        variant="contained"
                        size="small"
                        disabled={!watch('licenseCode')}
                        sx={{ height: '28px', marginRight: '4px' }}
                        startIcon={<ContentCopyIcon />}
                        onClick={() => {
                          //writeText : https, localhost에서만 동작
                          // navigator.clipboard.writeText(getValues('licenseCode'));
                          // enqueueSnackbar('License code has been copied to the clipboard.', {
                          //   variant: 'success',
                          //   autoHideDuration: 3000,
                          // });
                          const val = document.getElementById('licenseCode');
                          val.select();
                          let copyValue = document.execCommand('copy');
                          if (copyValue) {
                            enqueueSnackbar('License code has been copied to the clipboard.', {
                              variant: 'success',
                              autoHideDuration: 3000,
                            });
                          }
                        }}
                      >
                        Copy
                      </CustomButton>
                      <CustomButton
                        variant="contained"
                        size="small"
                        disabled={!getValues('licenseCode')}
                        sx={{ height: '28px' }}
                        startIcon={<DownloadIcon />}
                        onClick={handleSubmit(formCSVLicense)}
                      >
                        Download
                      </CustomButton>
                    </InputAdornment>
                  }
                />
              </CustomFormControl>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ErrorOutlineRoundedIcon color="warning" style={{ fontSize: 64 }} />
          <Typography id="modal-modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '28px' }}>
            Warning Rent-License
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, pb: 2 }}>
            Rent-license is for rental dealer. If you want to issue SW permanently, please uncheck this option.
          </Typography>
          <Button variant="contained" color="error" size="small" onClick={handleClose}>
            OK
          </Button>
        </Box>
      </Modal>
      <Modal
        open={errorMsgModalOpen}
        onClose={handleErrorMsgModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={customerErrorModalstyle}>
          <ErrorOutlineRoundedIcon color="warning" style={{ fontSize: 64 }} />
          <Typography id="modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '24px' }}>
            {errorMsg}
          </Typography>
          <Button variant="contained" color="error" size="small" onClick={handleErrorMsgModalClose}>
            OK
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default LicenseComponent;
