import React from 'react';
import { Card, CardHeader, CardContent, CardActions, Grid, Typography, Button } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import EmailIcon from '@mui/icons-material/Email';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { styled } from '@mui/material/styles';

import { USER_SERVER } from '../../Config';
import axios from 'axios';
axios.defaults.withCredentials = true;

const CustomCard = styled(Card)({
  // height: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginRight: '-10px',
  verticalAlign: 'middle',
  borderRight: 'thick solid transparent',
  '&:hover': {
    borderRight: 'thick solid orange',
  },
});

const ApproveMemberSubComponent = (props) => {
  // user accept,deny button handler
  const userStatusUpdateClickHandler = (e, userId, type) => {
    axios
      .post(`${process.env.REACT_APP_SERVER}${USER_SERVER}/manageuser/status`, { userId: userId, type: type })
      .then((res) => {
        if (res.data === 'ok') {
          props.setModalMessage('Updated');
          props.setOpenSuccessModal(true);
        } else {
          props.setModalMessage('Failed to Update');
          props.setOpenFailModal(true);
        }
      });
  };

  return (
    <>
      <CustomCard sx={{ mixHeight: '230px' }}>
        <CardHeader title={props.name}></CardHeader>
        <CardContent>
          <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1}>
            <Grid item>
              <EmailIcon />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">{props.email}</Typography>
            </Grid>
          </Grid>

          <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1}>
            <Grid item>
              <GroupsRoundedIcon />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle2">{props.groupname}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1}>
            <Grid item>
              <LocalPhoneIcon />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">{props.phonenumber}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1}>
            <Grid item>
              <ManageAccountsIcon />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">{props.rate}</Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions disableSpacing sx={{ mt: 'auto', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
          <Button
            variant="contained"
            color="error"
            size="small"
            sx={{ textTransform: 'none', marginRight: '12px' }}
            onClick={(e) => userStatusUpdateClickHandler(e, props.userId, 'accept')}
          >
            Accept
          </Button>
          <Button
            variant="outlined"
            color="error"
            size="small"
            sx={{ textTransform: 'none' }}
            onClick={(e) => userStatusUpdateClickHandler(e, props.userId, 'deny')}
          >
            Deny
          </Button>
        </CardActions>
      </CustomCard>
    </>
  );
};

export default ApproveMemberSubComponent;
