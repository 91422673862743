import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  NativeSelect,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';

import { DASHBOARD_SERVER } from '../../Config';

function DashboardPage() {
  const matches = useMediaQuery('(max-width:1024px)');
  const [issueYearsList, setIssueYearList] = useState();
  const [issueYear, setIssueYear] = useState(2017);
  const [issueRows, setIssueRows] = useState();
  const [salesYearList, setSalesYearList] = useState();
  const [salesYear, setSalesYear] = useState(2024);
  const [salesRows, setSalesRows] = useState();

  const handleIssueYearChange = (event) => {
    setIssueYear(event.target.value);
    getIssueResult(event.target.value);
  };

  const getIssueResult = (year) => {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentmonth = currentDate.getMonth();
    //변경된 년수로 sales count 재조회
    axios.get(`${process.env.REACT_APP_SERVER}${DASHBOARD_SERVER}/issue/${year}`).then((res) => {
      let rows = [];
      let productList = Object.keys(res.data);

      for (let i = 0; i < productList.length; i++) {
        //현재 년수 월 이후 집계 null 예외처리
        if (currentYear === year) {
          let count = res.data[productList[i]];
          for (let j = currentmonth + 1; j < count.length; j++) {
            count[j] = '-';
          }
          rows.push({ name: productList[i], count: count });
        } else {
          rows.push({ name: productList[i], count: res.data[productList[i]] });
        }
      }
      setIssueRows(rows);
    });
  };

  const getSalesResult = (year) => {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentmonth = currentDate.getMonth();
    //변경된 년수로 sales count 재조회
    axios.get(`${process.env.REACT_APP_SERVER}${DASHBOARD_SERVER}/sales/${year}`).then((res) => {
      let rows = [];
      let groupTypeList = Object.keys(res.data);

      for (let i = 0; i < groupTypeList.length; i++) {
        //현재 년수 월 이후 집계 null 예외처리
        if (currentYear === year) {
          let count = res.data[groupTypeList[i]];
          for (let j = currentmonth + 1; j < count.length; j++) {
            count[j] = '-';
          }
          rows.push({ name: groupTypeList[i], count: count });
        } else {
          rows.push({ name: groupTypeList[i], count: res.data[groupTypeList[i]] });
        }
      }
      setSalesRows(rows);
    });
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();

    // 2017년부터 현재 연도까지의 연도를 배열로 생성합니다
    let yearlist = [];
    for (let year = 2017; year <= currentYear; year++) {
      yearlist.push({ year: year });
    }
    setIssueYearList(yearlist);

    yearlist = [];
    for (let year = 2024; year <= currentYear; year++) {
      yearlist.push({ year: year });
    }
    setSalesYearList(yearlist);

    setIssueYear(currentYear);
    getIssueResult(currentYear);
    setSalesYear(currentYear);
    getSalesResult(currentYear);
  }, []);

  return (
    <div className="DashboardPage">
      <Container
        maxWidth="lx"
        sx={matches ? { paddingTop: '116px', paddingBottom: '120px' } : { paddingTop: '188px', paddingBottom: '120px' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{ height: '100%' }}>
            <Card
              elevation={1}
              sx={{ padding: '16px', mb: '16px', height: '560px', overflow: 'hidden', overflowY: 'scroll' }}
            >
              <Grid container spacing={1}>
                <Grid item xs={8} md={10}>
                  <Typography
                    variant="h5"
                    fontWeight={500}
                    sx={{
                      textAlign: 'left',
                      float: 'left',
                      color: 'rgba(197,197,197,1.00)',
                    }}
                  >
                    Monthly License Issue Count
                  </Typography>
                </Grid>
                <Grid item xs={4} md={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="select-label">Year</InputLabel>
                    {issueYearsList && (
                      <Select
                        labelId="select-label"
                        id="issueYearListSelect"
                        label="issueYear"
                        value={issueYear}
                        onChange={handleIssueYearChange}
                      >
                        {issueYearsList &&
                          issueYearsList.map((v, i) => {
                            return (
                              <MenuItem key={i} value={v.year}>
                                {v.year}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="salestable" size="small">
                      <TableHead style={{ backgroundColor: '#f5f5f5' }}>
                        <TableRow key="salestable">
                          <TableCell></TableCell>
                          <TableCell align="center">01</TableCell>
                          <TableCell align="center">02</TableCell>
                          <TableCell align="center">03</TableCell>
                          <TableCell align="center">04</TableCell>
                          <TableCell align="center">05</TableCell>
                          <TableCell align="center">06</TableCell>
                          <TableCell align="center">07</TableCell>
                          <TableCell align="center">08</TableCell>
                          <TableCell align="center">09</TableCell>
                          <TableCell align="center">10</TableCell>
                          <TableCell align="center">11</TableCell>
                          <TableCell align="center">12</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {issueRows &&
                          issueRows.map((row, i) => (
                            <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell align="right">{row.count[0]}</TableCell>
                              <TableCell align="right">{row.count[1]}</TableCell>
                              <TableCell align="right">{row.count[2]}</TableCell>
                              <TableCell align="right">{row.count[3]}</TableCell>
                              <TableCell align="right">{row.count[4]}</TableCell>
                              <TableCell align="right">{row.count[5]}</TableCell>
                              <TableCell align="right">{row.count[6]}</TableCell>
                              <TableCell align="right">{row.count[7]}</TableCell>
                              <TableCell align="right">{row.count[8]}</TableCell>
                              <TableCell align="right">{row.count[9]}</TableCell>
                              <TableCell align="right">{row.count[10]}</TableCell>
                              <TableCell align="right">{row.count[11]}</TableCell>
                              <TableCell align="right">{row.count[12]}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} sx={{ height: '100%' }}>
            <Card
              elevation={1}
              sx={{ padding: '16px', mb: '16px', height: '560px', overflow: 'hidden', overflowY: 'scroll' }}
            >
              <Grid container spacing={1}>
                <Grid item xs={8} md={10}>
                  <Typography
                    variant="h5"
                    fontWeight={500}
                    sx={{
                      textAlign: 'left',
                      float: 'left',
                      color: 'rgba(197,197,197,1.00)',
                    }}
                  >
                    Monthly License Sales Count
                  </Typography>
                </Grid>
                <Grid item xs={4} md={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="select-label">Year</InputLabel>
                    {issueYearsList && (
                      <Select
                        labelId="select-label"
                        id="salesYearListSelect"
                        label="salesYear"
                        value={salesYear}
                        // onChange={handleIssueYearChange}
                      >
                        {salesYearList &&
                          salesYearList.map((v, i) => {
                            return (
                              <MenuItem key={i} value={v.year}>
                                {v.year}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="salestable" size="small">
                      <TableHead style={{ backgroundColor: '#f5f5f5' }}>
                        <TableRow key="salestable">
                          <TableCell></TableCell>
                          <TableCell align="center">01</TableCell>
                          <TableCell align="center">02</TableCell>
                          <TableCell align="center">03</TableCell>
                          <TableCell align="center">04</TableCell>
                          <TableCell align="center">05</TableCell>
                          <TableCell align="center">06</TableCell>
                          <TableCell align="center">07</TableCell>
                          <TableCell align="center">08</TableCell>
                          <TableCell align="center">09</TableCell>
                          <TableCell align="center">10</TableCell>
                          <TableCell align="center">11</TableCell>
                          <TableCell align="center">12</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {salesRows &&
                          salesRows.map((row, i) => (
                            <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell align="right">{row.count[0]}</TableCell>
                              <TableCell align="right">{row.count[1]}</TableCell>
                              <TableCell align="right">{row.count[2]}</TableCell>
                              <TableCell align="right">{row.count[3]}</TableCell>
                              <TableCell align="right">{row.count[4]}</TableCell>
                              <TableCell align="right">{row.count[5]}</TableCell>
                              <TableCell align="right">{row.count[6]}</TableCell>
                              <TableCell align="right">{row.count[7]}</TableCell>
                              <TableCell align="right">{row.count[8]}</TableCell>
                              <TableCell align="right">{row.count[9]}</TableCell>
                              <TableCell align="right">{row.count[10]}</TableCell>
                              <TableCell align="right">{row.count[11]}</TableCell>
                              <TableCell align="right">{row.count[12]}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default DashboardPage;
