import React, { useEffect, useState } from 'react';
// import { registerUser } from '../../../_actions/user_actions';
import { registerUser, updateUserType } from '../../../redux/user';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  FormControl,
  FormHelperText,
  Grid,
  Modal,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import logoRy1 from '../../../assets/ry1.png';

import { GROUP_SERVER } from '../../Config';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
axios.defaults.withCredentials = true;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const theme = createTheme({
  main: 'rgba(0, 0, 0, 0.6)',
  orange: orange[500],
});

const CustomeGrid = styled(Grid)({
  height: '80px',
});

const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});
const CustomFormControl = styled(FormControl)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const pwRegExp = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[`~!@#$%^&*()-_=+.,?'])[^\s]{8,}$/;
const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
const nameRegExp = /^[\p{L}\p{N}`~!@#$%^&*()-_=+.,?']*$/u;

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email.').required(`What's your email address?`),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters.')
    .matches(pwRegExp, 'Password should contain at least one digit, one character and one punctuation.'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match.')
    .required('Confirm Password is required'),
  _group: Yup.string().required('Agency is required'),
  name: Yup.string().max(800, 'Name must be at most 800 character').required('Name is required').matches(nameRegExp, {
    message: `Only letters, numbers and punctuations (\`~!@#$%^&*()-_=+.,?') are allowed.`,
    excludeEmptyString: false,
  }),
  phonenumber: Yup.string().matches(phoneRegExp, {
    message: 'Phone number is not valid',
    excludeEmptyString: true,
  }),
});
const defaultValues = {
  name: '',
  email: '',
  role: 'user',
  password: '',
  confirmPassword: '',
  _group: '',
  phonenumber: '',
  status: 'Waiting for Approval',
  salt: '',
  DelphiTotalLicenseCount: '',
  DelphiAvailableLicenseCount: '',
};

function SignupPage(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [visiblePW, setVisiblePW] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const onSubmit = async (data, e) => {
    let formData = {
      name: data.name,
      email: data.email,
      role: data.role,
      password: data.password,
      _group: data._group,
      phonenumber: data.phonenumber,
      status: 'Waiting for Approval',
    };
    if (formData._group === 'default') {
      setFormErrorMessage(`Agency is required.`);
      setOpenErrorModal(true);
    } else {
      // async request which may result error
      try {
        dispatch(registerUser(formData)).then((res) => {
          if (res.payload.success) {
            dispatch(updateUserType('signup'));
            navigate('/login');
          } else {
            setFormErrorMessage(res.payload.message);
            setOpenErrorModal(true);
          }
        });
      } catch (e) {
        // handle your error
      }
    }
  };

  const handleErrorModalClose = () => {
    setFormErrorMessage('');
    setOpenErrorModal(false);
  };

  const onClickCancel = () => {
    navigate('/login');
  };

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}/group/list`).then((res) => {
      // console.log(res.data);
      setGroupList(res.data);
    });
  }, []);

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{
          // minHeight: '590px',
          // minWidth: '590px',
          height: isMobile ? '100%' : '720px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          borderRadius: 1,
          position: 'absolute',
          top: isMobile ? '' : '50%',
          msTransform: isMobile ? '' : 'translateY(-50%)',
          transform: isMobile ? '' : 'translateY(-50%)',
        }}
      >
        <Card elevation={5} sx={{ width: isMobile ? '100%' : '600px' }}>
          <CardMedia>
            <img
              src={logoRy1}
              alt="Rayence logo"
              style={{
                position: 'relative',
                float: 'right',
                top: '12px',
                right: '16px',
              }}
            />
          </CardMedia>

          <CardContent sx={{ padding: '20px 20px 0px 20px' }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  align="center"
                  sx={{
                    fontWeight: '600',
                    fontSize: '40px',
                    color: 'rgba(255,134,0,1.00)',
                    marginBottom: '8px',
                  }}
                >
                  Sign Up
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid item container direction="row" justifyContent="space-around" alignItems="stretch" spacing={2}>
                  <CustomeGrid item xs={12}>
                    <CustomTextField
                      fullWidth
                      required
                      variant="standard"
                      label="ID (Email)"
                      autoComplete="email"
                      {...register('email')}
                      value={watch('email') || ''}
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                      size="small"
                    />
                  </CustomeGrid>
                  <CustomeGrid item xs={12}>
                    <CustomTextField
                      fullWidth
                      required
                      variant="standard"
                      label="Password"
                      type={visiblePW ? 'text' : 'password'}
                      {...register('password')}
                      value={watch('password') || ''}
                      error={Boolean(errors.password)}
                      helperText={errors.password?.message}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              setVisiblePW(!visiblePW);
                            }}
                          >
                            {visiblePW && <VisibilityOffIcon style={{ width: '20px', height: '20px' }} />}
                            {!visiblePW && <VisibilityIcon style={{ width: '20px', height: '20px' }} />}
                          </IconButton>
                        ),
                      }}
                    />
                  </CustomeGrid>
                  <CustomeGrid item xs={12}>
                    <CustomTextField
                      fullWidth
                      required
                      variant="standard"
                      label="Confirm Password"
                      type="password"
                      {...register('confirmPassword')}
                      value={watch('confirmPassword') || ''}
                      error={Boolean(errors.confirmPassword)}
                      helperText={errors.confirmPassword?.message}
                      size="small"
                    />
                  </CustomeGrid>
                  <CustomeGrid item xs={12}>
                    <CustomFormControl variant="outlined" fullWidth style={{ marginTop: '12px' }}>
                      <InputLabel shrink id="select-group-label">
                        Agency *
                      </InputLabel>
                      <Select
                        labelId="select-group-label"
                        id="group-select"
                        label="Agency"
                        fullWidth
                        required
                        {...register('_group')}
                        defaultValue={'default'}
                        error={Boolean(errors._group)}
                        size="small"
                      >
                        <MenuItem key={'default'} value={'default'}>
                          {'Select a Agency...'}
                        </MenuItem>
                        {groupList.length > 0 &&
                          groupList.map((group) => {
                            return (
                              <MenuItem key={group._id} value={group._id}>
                                {group.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText size="small" style={{ margin: '4px 0px 0px 0px', color: '#d32f2f' }}>
                        {errors._group?.message}
                      </FormHelperText>
                    </CustomFormControl>
                  </CustomeGrid>
                  <CustomeGrid item xs={12}>
                    <CustomTextField
                      fullWidth
                      required
                      variant="standard"
                      label="Name"
                      autoComplete="name"
                      {...register('name')}
                      value={watch('name') || ''}
                      error={Boolean(errors.name)}
                      helperText={errors.name?.message}
                      size="small"
                    />
                  </CustomeGrid>

                  <CustomeGrid item xs={12}>
                    <CustomTextField
                      fullWidth
                      variant="standard"
                      label="Contact"
                      autoComplete="tel"
                      {...register('phonenumber')}
                      value={watch('phonenumber') || ''}
                      error={Boolean(errors.phonenumber)}
                      helperText={errors.phonenumber?.message}
                      FormHelperTextProps={{
                        sx: {
                          letterSpacing: '0.1px',
                          fontSize: '0.6rem',
                          lineHeight: 1,
                        },
                      }}
                      size="small"
                    />
                  </CustomeGrid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>

          <CardActions
            sx={{
              padding: '20px',
              justifyContent: 'center', // 수평 중앙 정렬
              alignItems: 'center', // 수직 중앙 정렬
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="error"
                  // disabled={submitting}
                  style={{ textTransform: 'none' }}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  style={{ textTransform: 'none' }}
                  onClick={onClickCancel}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Box>
      <Modal
        open={openErrorModal}
        onClose={handleErrorModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ErrorOutlineRoundedIcon color="warning" style={{ fontSize: 64 }} />
          <Typography id="modal-modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '24px' }}>
            {formErrorMessage}
          </Typography>

          <Button variant="contained" color="error" size="small" onClick={handleErrorModalClose}>
            OK
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default SignupPage;
