import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import EmailIcon from '@mui/icons-material/Email';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { styled } from '@mui/material/styles';
import ManageMemberDialog from './ManageMemberDialog';
import { USER_SERVER } from '../../Config';
import axios from 'axios';
axios.defaults.withCredentials = true;

const CustomCard = styled(Card)({
  marginRight: '-10px',
  cursor: 'pointer',
  verticalAlign: 'middle',
  borderRight: 'thick solid transparent',
  '&:hover': {
    borderRight: 'thick solid orange',
  },
});

const ApproveMemberSubComponent = (props) => {
  const [open, setOpen] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [userId, setUserId] = useState('');

  const handleOpen = (e) => {
    setOpen(true);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    props.userCahngeCheck(true);
    setOpen(false);
  };

  const deleteUserModalHandleClose = (e, userId) => {
    e.stopPropagation();
    setDeleteUserModalOpen(false);
    setUserId();
  };
  const userCardClickHandler = (e) => {
    handleOpen();
  };

  const userDeleteClickHandler = (e, userId) => {
    // button click bubbling 방지
    e.stopPropagation();
    setDeleteUserModalOpen(true);
    setUserId(userId);
  };

  // user Delete button handler
  const userDeleteModalClickHandler = (e) => {
    e.stopPropagation();

    axios.put(`${process.env.REACT_APP_SERVER}${USER_SERVER}/manageuser/${userId}`).then((res) => {
      setUserId('');
      setDeleteUserModalOpen(false);
      props.userCahngeCheck(true);
    });
  };
  // useEffect(() => {
  //   console.log(props.name);
  // }, [props]);

  return (
    <CustomCard onClick={userCardClickHandler}>
      <CardHeader title={props.name}></CardHeader>
      <CardContent>
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1}>
          <Grid item>
            <EmailIcon />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">{props.email}</Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1}>
          <Grid item>
            <GroupsRoundedIcon />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle2">{props.groupname}</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1}>
          <Grid item>
            <LocalPhoneIcon />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">{props.phonenumber}</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1}>
          <Grid item>
            <ManageAccountsIcon />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">{props.rate}</Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions
        sx={{
          alignSelf: 'stretch',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
        }}
      >
        {/* <Button
          variant="outlined"
          color="error"
          size="small"
          sx={{ textTransform: 'none' }}
          onClick={(e) => userDeleteClickHandler(e, props.userId)}
        >
          Remove
        </Button> */}
      </CardActions>
      <ManageMemberDialog open={open} handleClose={handleClose} userId={props.userId} />
      <Dialog
        open={deleteUserModalOpen}
        aria-hidden={deleteUserModalOpen ? 'false' : 'true'}
        onClose={deleteUserModalHandleClose}
      >
        <DialogTitle variant="h5">User Delete</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">Are you sure you want to delete this user?</Typography>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="outlined" onClick={deleteUserModalHandleClose} sx={{ textTransform: 'none' }}>
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={(e) => userDeleteModalClickHandler(e)}
            sx={{ textTransform: 'none' }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </CustomCard>
  );
};

export default ApproveMemberSubComponent;
