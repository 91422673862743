import React from 'react';
import { Button, Box, Modal, Typography, useMediaQuery } from '@mui/material';

import packageJson from '../../../../package.json';
import preval from 'preval.macro';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const AboutModal = (props) => {
  const matches = useMediaQuery('(max-width:1024px)');
  const buildDate = preval`module.exports = new Date();`;
  const localeBuildDate = new Date(buildDate.toLocaleString());

  let year = localeBuildDate.getFullYear();
  let month = (localeBuildDate.getMonth() + 1).toString().padStart(2, '0');
  let day = localeBuildDate.getDate().toString().padStart(2, '0');

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '36px' }}>
            License Server
          </Typography>

          <Typography id="modal-modal-description" variant="subtitle1">
            License Server ㅤv{packageJson.version}
          </Typography>
          <Typography id="modal-modal-description" variant="subtitle1">
            Updated ㅤ{year + '.' + month + '.' + day}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, pb: 2 }} variant="subtitle1">
            Copyright c. Rayence
          </Typography>
          <div style={{ marginTop: '16px', justifyContent: 'center' }}>
            <Button
              size="small"
              variant="contained"
              color="error"
              sx={{ textTransform: 'none', marginRight: '24px' }}
              onClick={props.handleClose}
            >
              Ok
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AboutModal;
