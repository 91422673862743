import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  FormControl,
  Grid,
  Modal,
  InputLabel,
  NativeSelect,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { styled, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import ApproveMemberSubComponent from './ApproveMemberSubComponent';
import SuccessModal from '../common/SuccessModal';
import FailModal from '../common/FailModal';
import { USER_SERVER, GROUP_SERVER } from '../../Config';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
axios.defaults.withCredentials = true;

const CustomGrid = styled(Grid)({
  minHeight: '64px',
});

const theme = createTheme({
  main: 'rgba(0, 0, 0, 0.6)',
  orange: orange[500],
});

const CustomFormControl = styled(FormControl)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const customerErrorModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const defaultValues = {
  agency: 'All',
  email: '',
  name: '',
  rate: 'user',
};
const validationSchema = Yup.object({
  email: Yup.string(),
  name: Yup.string('Enter your password'),
});

function ApproveMemberPage(props) {
  // const navigate = useNavigate();
  const matches = useMediaQuery('(max-width:1024px)');
  const [groupList, setGroupList] = useState([]);
  const [userList, setUserList] = useState([]);
  const user = useSelector((state) => state.user);
  const [errorMsgModalOpen, setErrorMsgModalOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openFailModal, setOpenFailModal] = useState(false);

  const updateModalHandleClose = (e) => {
    setModalMessage('');
    setOpenSuccessModal(false);
    setOpenFailModal(false);
    axios.post(`${process.env.REACT_APP_SERVER}${USER_SERVER}/approveuserlist`, { type: 'approve' }).then((res) => {
      setUserList(res.data);
    });
  };

  const updateModalSuccessHandleClose = (e) => {
    setModalMessage('');
    setOpenSuccessModal(false);
    setOpenFailModal(false);
    axios.post(`${process.env.REACT_APP_SERVER}${USER_SERVER}/approveuserlist`, { type: 'approve' }).then((res) => {
      setUserList(res.data);
    });
  };
  const handleErrorMsgModalClose = () => {
    setErrorMsg('');
    setErrorMsgModalOpen(false);
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data, e) => {
    let specialRule = /([`~!@#$%^&*()-_=+?.,?'\s])/gi;
    let wordRegex = /^[\p{L}0-9]+$/u;
    let copyName = data.name;
    let replaceName = copyName.replace(specialRule, '');
    if (replaceName === '' ? false : !wordRegex.test(replaceName)) {
      setErrorMsg(`Only letters, numbers and punctuations (\`~!@#$%^&*()-_=+.,?') are allowed.`);
      setErrorMsgModalOpen(true);
    } else {
      // async request which may result error
      try {
        // 조회 조건 param으로 request 요청
        data.type = 'approve';
        await axios.post(`${process.env.REACT_APP_SERVER}${USER_SERVER}/searchuser`, data).then((res) => {
          // console.log(res);
          setUserList(res.data);
        });
      } catch (e) {
        // handle your error
      }
    }
  };

  useEffect(() => {
    if (user.userData.isAuth) {
      axios.post(`${process.env.REACT_APP_SERVER}${USER_SERVER}/approveuserlist`, { type: 'approve' }).then((res) => {
        // console.log(res);
        setUserList(res.data);
      });
      axios.get(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}/group`).then((res) => {
        // console.log(res.data);
        setGroupList(res.data);
      });
    }
  }, []);

  return (
    <div className="ManageMemberPage">
      <Grid>
        <Grid
          container
          sx={
            matches
              ? { paddingTop: '100px', backgroundColor: '#e0e0e0' }
              : {
                  backgroundColor: '#e0e0e0',
                }
          }
        >
          <Grid item xs={12} container sx={{ border: '1px solid  #757575' }}>
            <Grid
              item
              container
              xs={12}
              md={10}
              sx={{
                padding: '16px 8px 8px 16px',
              }}
            >
              <Grid item container direction="row" spacing={1}>
                <CustomGrid item xs={6} md={3}>
                  <CustomFormControl type="text" variant="standard" fullWidth sx={{ height: '40px' }}>
                    <InputLabel shrink htmlFor="agency">
                      Agency
                    </InputLabel>
                    <NativeSelect id="agency" fullWidth size="small" {...register('agency')}>
                      <option value={'All'}>Select a Agency...</option>
                      {groupList &&
                        groupList.map((group) => {
                          return (
                            <option key={group._id} value={group._id}>
                              {group.name}
                            </option>
                          );
                        })}
                    </NativeSelect>
                  </CustomFormControl>
                </CustomGrid>

                <CustomGrid item xs={6} md={3}>
                  <CustomFormControl type="text" variant="standard" fullWidth sx={{ height: '40px' }}>
                    <InputLabel shrink htmlFor="rate">
                      Rate
                    </InputLabel>
                    <NativeSelect id="rate" fullWidth size="small" {...register('rate')}>
                      <option value={'All'}>Select a rate...</option>
                      <option value={'admin'}>Admin</option>
                      <option value={'super admin'}>Super Admin</option>
                      <option value={'user'}>User</option>
                    </NativeSelect>
                  </CustomFormControl>
                </CustomGrid>

                <CustomGrid item xs={6} md={3}>
                  <CustomFormControl fullWidth variant="standard">
                    <CustomTextField
                      fullWidth
                      variant="standard"
                      label="ID (Email)"
                      size="small"
                      autoComplete="email"
                      sx={{ color: 'red' }}
                      inputProps={{
                        maxLength: 135,
                      }}
                      error={Boolean(errors['email'])}
                      helperText={Boolean(errors['email']) ? 'Please enter a valid email.' : ''}
                      {...register('email')}
                    />
                  </CustomFormControl>
                </CustomGrid>

                <CustomGrid item xs={6} md={3}>
                  <CustomFormControl fullWidth variant="standard">
                    <CustomTextField
                      fullWidth
                      variant="standard"
                      label="Name"
                      size="small"
                      autoComplete="name"
                      sx={{ color: 'red' }}
                      inputProps={{
                        maxLength: 135,
                      }}
                      {...register('name')}
                      error={Boolean(errors['name'])}
                    />
                  </CustomFormControl>
                </CustomGrid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2} style={{ padding: '24px' }}>
              <Button
                type="submit"
                variant="contained"
                color="error"
                onClick={handleSubmit(onSubmit)}
                fullWidth
                sx={{ height: '100%', fontSize: 20, textTransform: 'none' }}
              >
                <SearchIcon sx={{ fontSize: 32 }} />
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            height: '800px',
            marginTop: '1px',
            padding: '12px',
            paddingBottom: '100px',
            backgroundColor: '#f5f5f5',
            // display: 'inline-flex',
            // flexDirection: 'column',
            overflow: 'hidden',
            overflowY: 'scroll',
          }}
          container
          spacing={3}
        >
          {userList &&
            userList.map((user) => {
              return (
                <Grid item xs={6} md={3} key={user._id}>
                  <ApproveMemberSubComponent
                    userId={user._id}
                    name={user.name}
                    email={user.email}
                    groupname={user.groupname}
                    phonenumber={user.phonenumber}
                    rate={user.role}
                    setModalMessage={setModalMessage}
                    setOpenSuccessModal={setOpenSuccessModal}
                    setOpenFailModal={setOpenFailModal}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Grid>

      <Modal
        open={errorMsgModalOpen}
        onClose={handleErrorMsgModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={customerErrorModalstyle}>
          <ErrorOutlineRoundedIcon color="warning" style={{ fontSize: 64 }} />
          <Typography id="modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '22px' }}>
            {errorMsg}
          </Typography>
          <Button variant="contained" color="error" size="small" onClick={handleErrorMsgModalClose}>
            OK
          </Button>
        </Box>
      </Modal>
      <SuccessModal messageTitle={modalMessage} open={openSuccessModal} handleClose={updateModalSuccessHandleClose} />
      <FailModal messageTitle={modalMessage} message={''} open={openFailModal} handleClose={updateModalHandleClose} />
    </div>
  );
}

export default ApproveMemberPage;
