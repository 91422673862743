import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Card,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  NativeSelect,
  Typography,
  TextField,
} from '@mui/material';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { styled, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { enqueueSnackbar } from 'notistack';

import GroupLicenseModal from './GroupLicenseModal';
import SuccessModal from './../common/SuccessModal';

import axios from 'axios';
import { GROUP_SERVER, GROUPTYPE_SERVER } from '../../Config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};
const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 460,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const theme = createTheme({
  // main: 'rgba(0, 0, 0, 0.6)',
  orange: orange[500],
});

const CustomFormControl = styled(FormControl)({
  '& .MuiInputLabel-root': {
    color: theme.main,
    padding: '4px',
  },
  '& .MuiNativeSelect-select': {
    padding: '6px',
    fontWeight: '600',
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const CustomButton = styled(Button)({
  textTransform: 'none',
});

function GroupLicenseBox() {
  const [groupList, setGroupList] = useState();
  const [selectedGroupList, setSelectedGroupList] = useState();
  const [groupTypeList, setGroupTypeList] = useState();
  const [seletedGroupType, setSelectedGroupType] = useState();
  const [groupLicenseCount, setGroupLicenseCount] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [paidOptions, setPaidOptions] = useState();
  const [checkedPaidOptions, setCheckedPaidOptions] = useState([]);

  const [open, setOpen] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [updateCheckMessage, setUpdateCheckMessage] = useState('');
  const [openDelGroupModal, setOpenDelGroupModal] = useState(false);
  const [openChangeLicenseModal, setOpenChangeLicenseModal] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [addGroupName, setAddGroupName] = useState();
  const [changeLicenseType, setChangeLicenseType] = useState();

  //modal handler
  const modalHandleClose = (e) => {
    // e.stopPropagation();
    setOpen(false);
    setOpenDelGroupModal(false);
    setOpenChangeLicenseModal(false);
  };
  const delModalHandleOpen = (e) => {
    e.stopPropagation();
    setOpenDelGroupModal(true);
  };
  const modalHandleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleErrorModalClose = () => {
    setFormErrorMessage('');
    setOpenErrorModal(false);
  };
  const handleUpdateConfirmModalClose = () => {
    setUpdateConfirmModal(false);
    setUpdateCheckMessage('');
  };
  // refresh onClick
  const refreshOnclick = (e) => {
    setRefresh(true);
  };

  // paid option checked onchange
  const handlePaidOptionsChange = (event) => {
    if (event.target.checked) {
      setCheckedPaidOptions([...checkedPaidOptions, event.target.name]);
    } else {
      let options = checkedPaidOptions;
      let findListIdx = options.findIndex((item) => item === event.target.name);
      options.splice(findListIdx, 1);

      setCheckedPaidOptions([...options]);
    }
  };

  //grouptype combobox onchange
  const handleGroupListChange = (e) => {
    // console.log(e.target.value);
    setSelectedGroupList(e.target.value);
    setSelectedGroupType(groupTypeList[e.target.value].groupTypeName);
    setSelectedIndex();
    setGroupLicenseCount('');

    // 선택한 group type의 license option 조회
    axios
      .get(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}/${groupTypeList[e.target.value]._id}/options`)
      .then((res) => {
        setPaidOptions(res.data.options);
      });
  };

  //group listitem onclick
  const handleListItemClick = (event, index, value) => {
    setSelectedIndex(index);

    if (seletedGroupType) {
      if (value[seletedGroupType]) {
        setGroupLicenseCount(value[seletedGroupType].available);
      } else {
        setGroupLicenseCount(0);
      }
    } else {
      setGroupLicenseCount('');
    }
  };
  //add group
  const addGroup = () => {
    if (addGroupName !== '' && addGroupName !== undefined) {
      let wordRegex = /^(?=.*\S)[\p{L}\p{N} ~!@#$%^&*()\-_+=?.,?']*[^ ]$/u;

      if (addGroupName === '' ? false : !wordRegex.test(addGroupName)) {
        setFormErrorMessage(`Only letters, numbers and punctuations (\`~!@#$%^&*()-_=+.,?') are allowed.`);
        setOpenErrorModal(true);
      } else {
        axios.put(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}`, { name: addGroupName }).then((res) => {
          if (res.data.err) {
            setFormErrorMessage(res.data.msg);
            setOpenErrorModal(true);
          } else {
            setUpdateCheckMessage('Agency creation completed.');
            setUpdateConfirmModal(true);
            setOpen(false);
            getGroupList();
          }
        });
      }
    } else {
      setFormErrorMessage('Enter name of agency.');
      setOpenErrorModal(true);
    }
    setAddGroupName();
  };

  //delete group
  const deleteGroup = () => {
    // console.log(groupList[selectedIndex]);
    if (groupList[selectedIndex] !== undefined) {
      axios.delete(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}/${groupList[selectedIndex]._id}`).then((res) => {
        setUpdateCheckMessage('Agency deletion completed.');
        setUpdateConfirmModal(true);
        setOpenDelGroupModal(false);
        getGroupList();
      });
    } else {
      setFormErrorMessage('You can delete an agent after you select it.');
      setOpenErrorModal(true);
      setOpenDelGroupModal(false);
      getGroupList();
    }
  };

  const deleteGEGroupAndSetGroupType = (list) => {
    let findListIdx = list.findIndex((item) => item._id === '667a0735b2263d0443b1f1dc');
    list.splice(findListIdx, 1);
    setGroupTypeList(list);
  };

  const getGroupList = () => {
    //groupType정보 DB로 부터 가져오기
    axios.get(`${process.env.REACT_APP_SERVER}${GROUPTYPE_SERVER}/type`).then((res) => {
      deleteGEGroupAndSetGroupType(res.data);
    });

    axios.get(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}/group`).then((res) => {
      setGroupList(res.data);
    });
    setRefresh(false);
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER}${GROUPTYPE_SERVER}/type`).then((res) => {
      deleteGEGroupAndSetGroupType(res.data);
    });

    axios.get(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}/group`).then((res) => {
      setGroupList(res.data);
    });
  }, []);

  useEffect(() => {
    if (refresh) {
      getGroupList();
      //license copies count update
      if (selectedIndex !== undefined && seletedGroupType !== undefined) {
        setGroupLicenseCount(groupList[selectedIndex][seletedGroupType].available);
      }
      enqueueSnackbar('Group List Refresh Completed', {
        variant: 'success',
        autoHideDuration: 2500,
      });
    }
  }, [refresh]);

  //전체 list update 될때 현재 선택되어있는 목록 있는 경우 copies count update
  useEffect(() => {
    if (groupList !== undefined && selectedIndex !== undefined && seletedGroupType !== undefined) {
      setGroupLicenseCount(groupList[selectedIndex][seletedGroupType].available);
    }
  }, [groupList]);

  return (
    <>
      <Card elevation={1} sx={{ padding: '12px' }}>
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item xs={12}>
            <Box
              p={1}
              style={{
                minHeight: '190px',
                whiteSpace: 'pre-wrap',
                backgroundColor: 'rgba(220,220,220,1.00)',
              }}
            >
              <div
                style={{
                  float: 'right',
                }}
              >
                <Button size="small" style={{ minWidth: '32px', marginRight: '8px' }} onClick={modalHandleOpen}>
                  <AddIcon sx={{ color: 'black' }} />
                </Button>
                {/* <Button size="small" style={{ minWidth: '32px' }} onClick={delModalHandleOpen}>
                  <RemoveIcon style={{ color: 'black' }} />
                </Button> */}
              </div>
              <Typography variant="h4" fontWeight={500} style={{ fontSize: '26px', padding: '12px' }}>
                Group License
              </Typography>
              <Divider />
              <Typography m={1} variant="subtitle1" fontSize={15}>{`Agency \n`}</Typography>
              <Typography
                m={1}
                variant="subtitle1"
                fontSize={15}
              >{`Select License, after that, select group.\n`}</Typography>
              <Typography m={1} variant="subtitle1" fontSize={15}>
                {`After increasing or decreasing the license quantity, Click Refresh button.\n`}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4} container justifyContent="flex-start">
            <Typography color={'rgba(127,127,127,1.00)'} style={{ paddingLeft: '20px' }}>
              <b style={{ color: 'red', fontSize: '24px' }}>{groupLicenseCount}</b> Copies
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} container justifyContent="flex-end">
            <CustomButton
              color="error"
              variant="contained"
              size="small"
              style={{ margin: '4px 0px 4px 4px', fontSize: '0.72rem' }}
              startIcon={<AutorenewIcon />}
              onClick={refreshOnclick}
            >
              Refresh
            </CustomButton>
            <CustomButton
              color="error"
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              style={{ margin: '4px 0px 4px 4px', fontSize: '0.72rem' }}
              disabled={selectedGroupList === 'none' || selectedGroupList === undefined || selectedIndex === undefined}
              onClick={(e) => {
                setChangeLicenseType('Add');
                setOpenChangeLicenseModal(true);
              }}
            >
              License
            </CustomButton>
            <CustomButton
              color="error"
              variant="contained"
              size="small"
              startIcon={<RemoveIcon />}
              style={{
                margin: '4px 0px 4px 4px',
                fontSize: '0.72rem',
              }}
              disabled={selectedGroupList === 'none' || selectedGroupList === undefined || selectedIndex === undefined}
              onClick={(e) => {
                setChangeLicenseType('Remove');
                setOpenChangeLicenseModal(true);
              }}
            >
              License
            </CustomButton>
          </Grid>

          <Grid item xs={12} style={{ border: '1px solid rgba(197,197,197,1.00)' }}>
            <CustomFormControl fullWidth>
              <InputLabel variant="standard" htmlFor="group-licenses">
                Licenses
              </InputLabel>
              {groupTypeList && (
                <NativeSelect value={selectedGroupList} onChange={handleGroupListChange} id="group-licenses">
                  <option value={'none'}>--- Slect a License type ---</option>
                  {groupTypeList &&
                    groupTypeList.map((v, i) => {
                      return (
                        <option key={v._id} value={i}>
                          {v.groupTypeName}
                        </option>
                      );
                    })}
                </NativeSelect>
              )}
            </CustomFormControl>
          </Grid>
          <Grid item xs={12}>
            <FormGroup style={{ paddingLeft: '8px' }}>
              {paidOptions && paidOptions.length > 0 && <FormLabel component="legend">Paid Options</FormLabel>}
              {paidOptions &&
                paidOptions.map((v, i) => {
                  // console.log(v);
                  // console.log(groupList[selectedIndex]);
                  return (
                    <div key={v.option.optionName}>
                      <FormControlLabel
                        key={i}
                        size="small"
                        control={<Checkbox onChange={handlePaidOptionsChange} name={v.option.optionName} />}
                        label={v.option.optionName}
                      />
                      <div style={{ float: 'right' }}>
                        <b style={{ color: 'red', fontSize: '16px' }}>
                          {selectedIndex >= 0 &&
                            seletedGroupType &&
                            `${
                              groupList[selectedIndex].hasOwnProperty(seletedGroupType)
                                ? groupList[selectedIndex][seletedGroupType].hasOwnProperty('options')
                                  ? groupList[selectedIndex][seletedGroupType].options.hasOwnProperty(
                                      v.option.optionName
                                    )
                                    ? groupList[selectedIndex][seletedGroupType].options[v.option.optionName].available
                                    : 0
                                  : 0
                                : 0
                            } `}
                        </b>
                        Copies
                      </div>
                    </div>
                  );
                })}
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              border: '1px solid rgba(197,197,197,1.00)',
              height: '320px',
              overflow: 'hidden',
              overflowY: 'scroll',
            }}
          >
            {groupList && (
              <List id="group-list" disablePadding>
                {groupList &&
                  groupList.map((v, i) => {
                    return (
                      <ListItemButton
                        divider
                        key={v._id}
                        selected={selectedIndex === i}
                        onClick={(event) => handleListItemClick(event, i, v)}
                      >
                        <ListItemText primary={i + 1 + 'ㅤㅤ' + v.name} />
                      </ListItemButton>
                    );
                  })}
              </List>
            )}
          </Grid>
        </Grid>
      </Card>
      <Dialog
        open={open}
        aria-hidden={open ? 'false' : 'true'}
        onClose={modalHandleClose}
        maxWidth={'sm'}
        style={{ minWidth: '360px' }}
      >
        <DialogTitle variant="h5" align="center">
          Add Agency
        </DialogTitle>
        <DialogContent align="center" style={{ minWidth: '300px' }}>
          <Typography variant="subtitle1">Enter name of agency to register</Typography>
          <TextField
            fullWidth
            size="small"
            inputProps={{
              maxLength: 60,
            }}
            onChange={(e) => {
              setAddGroupName(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            onClick={modalHandleClose}
            sx={{ textTransform: 'none' }}
          >
            No
          </Button>
          <Button size="small" variant="contained" color="error" onClick={addGroup} sx={{ textTransform: 'none' }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={openDelGroupModal}
        aria-hidden={openDelGroupModal ? 'false' : 'true'}
        onClose={modalHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ErrorOutlineRoundedIcon color="warning" style={{ fontSize: 64 }} />
          <Typography id="modal-modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '28px' }}>
            Are you sure?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, pb: 2 }}>
            You will not be able to recover this group!!!
          </Typography>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              onClick={modalHandleClose}
              sx={{ textTransform: 'none', marginRight: '20px' }}
            >
              No
            </Button>

            <Button size="small" variant="contained" color="error" onClick={deleteGroup} sx={{ textTransform: 'none' }}>
              Yes
            </Button>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openErrorModal}
        onClose={handleErrorModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ErrorOutlineRoundedIcon color="warning" style={{ fontSize: 64 }} />
          <Typography id="modal-modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '24px' }}>
            {formErrorMessage}
          </Typography>

          <Button variant="contained" color="error" size="small" onClick={handleErrorModalClose}>
            OK
          </Button>
        </Box>
      </Modal>

      <SuccessModal
        messageTitle={updateCheckMessage}
        open={updateConfirmModal}
        handleClose={handleUpdateConfirmModalClose}
      />
      <GroupLicenseModal
        open={openChangeLicenseModal}
        onClose={modalHandleClose}
        licenseType={changeLicenseType}
        groupList={groupList}
        selectedIndex={selectedIndex}
        groupType={seletedGroupType}
        groupTypeList={groupTypeList}
        groupTypeIndex={selectedGroupList}
        getGroupList={getGroupList}
        checkedPaidOptions={checkedPaidOptions}
      />
    </>
  );
}

export default GroupLicenseBox;
