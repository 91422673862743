import ExcelJS from 'exceljs';
import moment from 'moment';

export function removeProperty(obj, propertyName) {
  let { [propertyName]: _, ...result } = obj;
  return result;
}

export function downloadLicenseFile(data) {
  let filename = 'Rayence License Key.txt';

  let blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    let link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export async function createAndDownloadLicenseHistoryXlsx(rows) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('history');

  let reformData = [];
  reformData = rows.map((row) => {
    let data = {};
    data['No'] = row.no;
    data['Agency'] = row.agency.name;
    data['LicenseType'] = row.groupType.name;
    data['Paid Options'] = row.options.length > 0 ? row.options.join() : '-';
    data['Count'] = row.count;
    data['PIC'] = row.pic.name;
    data['Action Type'] = row.actionType;
    data['Date'] = moment(row.date).format('YYYY.MM.DD HH:mm');
    return data;
  });

  // console.log(reformData);

  const columns = Object.keys(reformData[0]);
  worksheet.columns = columns.map((column) => ({
    header: column,
    key: column,
    width: 20,
  }));
  worksheet.columns[0].width = 4;

  // 각 헤더 cell에 스타일 지정

  const headerStyle = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'e0e0e0' },
  };

  for (let i = 1; i <= worksheet.columnCount; i++) {
    const headerEachCell = worksheet.getCell(`${String.fromCharCode(i + 64)}1`);
    headerEachCell.fill = headerStyle;
    headerEachCell.font = {
      bold: true,
    };
  }

  worksheet.insertRows(2, reformData);

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = window.URL.createObjectURL(blob); // blob으로 객체 URL 생성
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = 'Rayence License History.xlsx';
  anchor.click(); // anchor를 다운로드 링크로 만들고 강제로 클릭 이벤트 발생
  window.URL.revokeObjectURL(url); // 메모리에서 해제
}

export async function downloadLicenseHistoryXlsx(rows) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('history');
  // console.log(rows);
  let reformData = [];
  reformData = rows.map((row) => {
    let data = {};
    data['No'] = row.no;
    data['Agency'] = row._group.name;
    data['Customer'] = row._customer;
    data['S/W'] = row._product.name;
    data['Optional'] = row.options.length > 0 ? row.options.join() : '-';
    data['Type'] = row.type;
    data['Rent'] = row.rent ? row.rent : '-';
    data['Publisher'] = row._publisher ? row._publisher.name : 'deleted user';
    data['Request Code'] = row.requestCode;
    data['License Code'] = row.licenseCode;
    data['Date'] = moment(row.date).format('YYYY.MM.DD HH:mm');
    return data;
  });

  // console.log(reformData);

  const columns = Object.keys(reformData[0]);
  worksheet.columns = columns.map((column) => ({
    header: column,
    key: column,
    width: 20,
  }));
  worksheet.columns[0].width = 4;

  // 각 헤더 cell에 스타일 지정

  const headerStyle = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'e0e0e0' },
  };

  for (let i = 1; i <= worksheet.columnCount; i++) {
    const headerEachCell = worksheet.getCell(`${String.fromCharCode(i + 64)}1`);
    headerEachCell.fill = headerStyle;
    headerEachCell.font = {
      bold: true,
    };
  }

  worksheet.insertRows(2, reformData);

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = window.URL.createObjectURL(blob); // blob으로 객체 URL 생성
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = 'Rayence License History.xlsx';
  anchor.click(); // anchor를 다운로드 링크로 만들고 강제로 클릭 이벤트 발생
  window.URL.revokeObjectURL(url); // 메모리에서 해제
}

export function vsObject(obj1, obj2) {
  // obj1, obj2 비교
  if (obj1 !== obj2) {
    const keys1 = Object.keys(obj1); // obj1 객체의 속성들을 담은 배열
    const keys2 = Object.keys(obj2); // obj2 객체의 속성들을 담은 배열

    // 두 keys 배열의 길이가 다르면
    if (keys1.length !== keys2.length) {
      // 두 객체의 속성이 일치하지 않기 때문
      //   console.log(`${obj1}과 ${obj2}는 다른 내용입니다.`);
      return false;
    }

    // keys 배열 중 하나의 요소 순회
    for (let key of keys1) {
      // obj1과 obj2의 key 속성값이 다르면
      if (obj1[key] !== obj2[key]) {
        // obj1과 obj2의 속성값이 일치하지 않기 때문
        // console.log(`${obj1}과 ${obj2}는 다른 내용입니다.`);
        return false;
      }
    }

    // (keys 요소 순회 종료 후)
    // obj1과 obj2의 모든 속성과 속성값이 일치하기 때문
    // console.log(`${obj1}과 ${obj2}는 같은 내용입니다.`);
    return true;
  }
}

export function isEmptyObject(param) {
  return Object.keys(param).length === 0 && param.constructor === Object;
}
