import React, { useState } from 'react';
import { Button, Box, Modal, Typography } from '@mui/material';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { GROUPTYPE_SERVER, PRODUCT_SERVER, OPTION_SERVER, EMAIL_SERVER } from '../../Config';
import axios from 'axios';

import SuccessModal from '../common/SuccessModal';
import FailModal from './../common/FailModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 460,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const ManageDeleteModal = (props) => {
  // result message modal
  const [modalMessage, setModalMessage] = useState('');
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openFailModal, setOpenFailModal] = useState(false);

  const resultModalHandleClose = (e) => {
    setModalMessage('');
    setOpenSuccessModal(false);
    setOpenFailModal(false);
  };
  const submit = () => {
    let modalType = props.details.modalType;
    if (modalType === 'License') {
      //license type delete
      axios.post(`${process.env.REACT_APP_SERVER}${GROUPTYPE_SERVER}`, props.details).then((res) => {
        if (!res.data.err) {
          setModalMessage('Updated Successfully');
          setOpenSuccessModal(true);
          props.handleClose();
        }
      });
    } else if (modalType === 'sw') {
      //sw status false
      axios.post(`${process.env.REACT_APP_SERVER}${PRODUCT_SERVER}`, props.details).then((res) => {
        if (!res.data.err) {
          setModalMessage('Updated Successfully');
          setOpenSuccessModal(true);
          props.handleClose();
        }
      });
    } else if (modalType === 'option') {
      //option status false
      axios.post(`${process.env.REACT_APP_SERVER}${OPTION_SERVER}`, props.details).then((res) => {
        if (!res.data.err) {
          setModalMessage('Updated Successfully');
          setOpenSuccessModal(true);
          props.handleClose();
        }
      });
    } else if (modalType === 'Email') {
      axios.post(`${process.env.REACT_APP_SERVER}${EMAIL_SERVER}`, props.details).then((res) => {
        if (!res.data.err) {
          setModalMessage('Updated Successfully');
          setOpenSuccessModal(true);
          props.handleClose();
        }
      });
    }
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ErrorOutlineRoundedIcon color="warning" style={{ fontSize: 64 }} />
          <Typography id="modal-modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '28px' }}>
            Are you sure?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, pb: 2 }}>
            Once this {<b style={{ color: 'red' }}>{props.details && props.details.message}</b>} is deleted, it cannot
            be restored.
          </Typography>
          <div style={{ justifyContent: 'center' }}>
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              onClick={props.handleClose}
              sx={{ textTransform: 'none', marginRight: '24px' }}
            >
              No
            </Button>
            <Button
              size="small"
              variant="contained"
              color="error"
              sx={{ textTransform: 'none' }}
              onClick={() => {
                submit();
              }}
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
      <SuccessModal messageTitle={modalMessage} open={openSuccessModal} handleClose={resultModalHandleClose} />
      <FailModal messageTitle={modalMessage} open={openFailModal} handleClose={resultModalHandleClose} />
    </div>
  );
};

export default ManageDeleteModal;
