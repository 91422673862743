import React from 'react';
import { Button, Box, Modal, Typography } from '@mui/material';

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 460,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const SuccessModal = (props) => {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CheckCircleOutlineRoundedIcon color="success" style={{ fontSize: 64 }} />
          <Typography id="modal-modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '28px' }}>
            {props && `${props.messageTitle}`}
          </Typography>
          {/* <Typography id="modal-modal-description" sx={{ mt: 2, pb: 2 }}>
            {props && `${props.message}`}
          </Typography> */}
          <Button variant="contained" color="error" size="small" onClick={props.handleClose}>
            OK
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default SuccessModal;
