import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Grid, Pagination, PaginationItem } from '@mui/material';
import {
  DataGrid,
  // gridPaginationRowCountSelector,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';

import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/material/styles';
import { setHistory } from '../../../redux/history';
import moment from 'moment';
// import { resetHistory } from '../../../redux/history';
import FailModal from '../common/FailModal';

import { isEmptyObject, createAndDownloadLicenseHistoryXlsx } from '../../../utils/util';

const CustomGrid = styled(Grid)({
  marginBottom: '12px',
});

const HistorySubCompoent = (props) => {
  const dispatch = useDispatch();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [openFailModal, setOpenFailModal] = useState(false);

  const history = useSelector((state) => state.history.historyData);

  const modalHandleClose = () => {
    setOpenFailModal(false);
  };

  const column = [
    {
      sortable: true,
      field: 'no',
      headerName: 'No',
      flex: 0,
      width: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'agency',
      headerName: 'Agency',
      headerAlign: 'center',
      align: 'center',
      flex: 2.5,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value.name}</div>
      ),
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    },
    {
      field: 'groupType',
      headerName: 'LicenseType',
      headerAlign: 'center',
      align: 'center',
      flex: 2.5,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value.name}</div>
      ),
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    },
    {
      field: 'options',
      headerName: 'Paid Options',
      headerAlign: 'center',
      align: 'center',
      width: 360,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value ? params.value.join(', ') : '-'}
        </div>
      ),
      sortComparator: (v1, v2) => {
        const name1 = v1 || ''; // v1이 undefined일 경우 빈 문자열로 대체
        const name2 = v2 || ''; // v2가 undefined일 경우 빈 문자열로 대체
        return name1.join().localeCompare(name2.join());
      },
    },
    {
      field: 'count',
      headerName: 'Count',
      headerAlign: 'center',
      align: 'center',
      flex: 1.5,
    },
    {
      field: 'pic',
      headerName: 'PIC',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value.name}</div>
      ),
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    },
    {
      field: 'actionType',
      headerName: 'Action Type',
      headerAlign: 'center',
      align: 'center',
      flex: 1.5,
    },
    {
      field: 'date',
      headerName: 'Date',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {moment(params.value).format('YYYY.MM.DD HH:mm')}
        </div>
      ),
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
  ];

  // const onRowsSelectionHandler = (ids) => {
  //   const selectedRowsData = ids.map((id) => props.history.find((row) => row.id === id));
  //   console.log(selectedRowsData);
  // };

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    //filtering total row
    // console.log(useGridSelector(apiRef, gridPaginationRowCountSelector));
    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

  function handleSetSelectedRow(clickedRow) {
    props.history.map((x, i) => {
      if (x._id === clickedRow) {
        dispatch(setHistory({ idx: i, historyData: x }));
      }
    });
  }

  const downloadXlsx = (rows, rowcount) => {
    if (rowcount > 1000) {
      setOpenFailModal(true);
    } else if (rowcount > 0) {
      // console.log(rows);
      createAndDownloadLicenseHistoryXlsx(rows);
    }
  };

  useEffect(() => {
    if (props.history && rowSelectionModel)
      if (rowSelectionModel) {
        handleSetSelectedRow(rowSelectionModel);
      }
  }, [props.history]);

  return (
    <div>
      <Grid container>
        <CustomGrid item xs={12}>
          <div>
            <div style={{ float: 'left', fontSize: '16px', paddingLeft: '8px' }}>
              <b>{props.total}</b> Result(s)
            </div>

            <Button
              variant="contained"
              size="small"
              startIcon={<DownloadIcon />}
              style={{
                height: '100%',
                textTransform: 'none',
                float: 'right',
              }}
              disabled={!props.history || props.total === 0}
              onClick={() => {
                downloadXlsx(props.history, props.total);
              }}
            >
              Download
            </Button>
          </div>
        </CustomGrid>
        <CustomGrid item xs={12} container>
          <Box
            sx={{
              height: '720px',
              maxHeight: '720px',
              width: '100%',
            }}
          >
            <DataGrid
              sx={{
                height: '100%',
                '& .MuiDataGrid-columnHeaderTitle ': {
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-columnHeader': {
                  fontWeight: 900,
                  fontSize: '15px',
                  backgroundColor: '#F3F3F3',
                  border: '1px #E2E6E9',
                },
                '& .MuiDataGrid-cell': {
                  borderColor: '#E2E6E9',
                  padding: '5px 10px 5px 10px',
                },
              }}
              getRowId={(row) => row['_id']}
              getRowHeight={() => 'auto'}
              columns={column}
              rows={props.history}
              initialState={{
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{
                pagination: CustomPagination,
              }}
              pageSizeOptions={[5, 10, 20, 50]}
              pagination
              disableColumnSelector
              showCellVerticalBorder
            />
          </Box>
        </CustomGrid>
      </Grid>
      <FailModal
        messageTitle={'The number of issues to download cannot exceed 1000.'}
        message={''}
        open={openFailModal}
        handleClose={modalHandleClose}
      />
    </div>
  );
};

export default HistorySubCompoent;
