import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Container,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import ManageEmailModal from './ManageEmailModal';
import ManageDeleteModal from './ManageDeleteModal';

import axios from 'axios';
import { EMAIL_SERVER } from '../../Config';

const CustomButton = styled(Button)({
  textTransform: 'none',
});

function SettingPage() {
  const matches = useMediaQuery('(max-width:1024px)');
  const [emailList, setEmailList] = useState();
  const [selectedEmailIndex, setSelectedEmailIndex] = useState(0);

  //email modal 관련 state
  const [openEmailModal, setOpenEmailModal] = useState(false);

  //delete modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteModalProps, setDeleteModalProps] = useState();

  //modal handler
  const modalHandleClose = (e) => {
    setOpenEmailModal(false);
    setOpenDeleteModal(false);
    getGroupList();
  };

  //Email list Item Click
  const handleGroupTypeListItemClick = (event, index, value) => {
    setSelectedEmailIndex(index);
  };

  const getGroupList = () => {
    // email list default load
    axios.get(`${process.env.REACT_APP_SERVER}${EMAIL_SERVER}`).then((res) => {
      setEmailList(res.data);
      setSelectedEmailIndex(0);
    });
  };

  useEffect(() => {
    // email list default load
    getGroupList();
  }, []);

  return (
    <div className="SettingPage">
      <Container
        maxWidth="lg"
        sx={matches ? { paddingTop: '116px', paddingBottom: '120px' } : { paddingTop: '188px', paddingBottom: '120px' }}
      >
        <Card elevation={1} sx={{ padding: '16px', mb: '16px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3} md={3}>
              <Typography
                variant="h5"
                fontWeight={500}
                sx={{
                  textAlign: 'left',
                  float: 'left',
                  color: 'rgba(197,197,197,1.00)',
                }}
              >
                Email Recipient List
              </Typography>
            </Grid>
            {!matches && <Divider orientation="vertical" flexItem sx={{ marginLeft: '-1px' }} />}
            <Grid item xs={12} sm={9} md={9}>
              <Grid item xs={12} container justifyContent="flex-end">
                <CustomButton
                  variant="contained"
                  style={{ margin: '4px 0px 4px 4px', fontSize: '0.72rem' }}
                  onClick={() => {
                    setOpenEmailModal(true);
                  }}
                >
                  Add
                </CustomButton>

                <CustomButton
                  color="error"
                  variant="contained"
                  style={{
                    margin: '4px 0px 4px 4px',
                    fontSize: '0.72rem',
                  }}
                  onClick={(e) => {
                    setDeleteModalProps({
                      type: 'Delete',
                      modalType: 'Email',
                      message: 'Email address',
                      id: emailList[selectedEmailIndex]._id,
                    });
                    setOpenDeleteModal(true);
                  }}
                >
                  Delete
                </CustomButton>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  border: '1px solid rgba(197,197,197,1.00)',
                  height: '372px',
                  overflow: 'hidden',
                  overflowY: 'scroll',
                }}
              >
                {emailList && (
                  <List id="email-list" disablePadding>
                    {emailList &&
                      emailList.map((v, i) => {
                        return (
                          <ListItemButton
                            dense
                            divider
                            key={v._id}
                            selected={selectedEmailIndex === i}
                            onClick={(event) => handleGroupTypeListItemClick(event, i, v)}
                          >
                            <ListItemText primary={i + 1 + 'ㅤㅤ' + v.email} />
                          </ListItemButton>
                        );
                      })}
                  </List>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Card>

        <ManageEmailModal open={openEmailModal} handleClose={modalHandleClose} />
        <ManageDeleteModal open={openDeleteModal} handleClose={modalHandleClose} details={deleteModalProps} />
      </Container>
    </div>
  );
}

export default SettingPage;
