import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loginUser, updateUserType } from '../../../redux/user';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Box,
  Checkbox,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Modal,
  Typography,
  useMediaQuery,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { styled, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import loginBgImg from '../../../assets/img-login.png';
import logoRy1 from '../../../assets/ry1.png';
import axios from 'axios';
axios.defaults.withCredentials = true;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 460,
  bgcolor: 'rgb(253,237,237)',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 460,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const theme = createTheme({
  main: 'rgba(0, 0, 0, 0.6)',
  orange: orange[500],
});

const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const validationSchema = Yup.object({
  email: Yup.string().email('Please enter a valid email.').required('Please enter an email.'),
  password: Yup.string().required('Please enter a password.'),
});

function LoginPage(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const rememberMeChecked = localStorage.getItem('rememberMe') ? true : false;
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(rememberMeChecked);
  const [visiblePW, setVisiblePW] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);

  const onClickSignUp = () => {
    navigate('/signup');
  };

  const handleClose = () => {
    setFormErrorMessage('');
    setOpen(false);
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const handleUpdateConfirmModalClose = () => {
    setUpdateConfirmModal(false);
    dispatch(updateUserType(''));
  };

  const initialEmail = localStorage.getItem('rememberMe') ? localStorage.getItem('userId') : '';

  const onSubmit = (data, e) => {
    try {
      setTimeout(() => {
        dispatch(loginUser(data)).then((res) => {
          if (res.payload.loginSuccess) {
            window.localStorage.setItem('userId', res.payload.userId);
            if (rememberMe === true) {
              window.localStorage.setItem('rememberMe', data.email);
            } else {
              localStorage.removeItem('rememberMe');
            }
            navigate('/license');
          } else {
            setFormErrorMessage(res.payload.message);
          }
        });
        setSubmitting(false);
      }, 500);
    } catch (e) {
      // handle your error
    }
  };

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: initialEmail,
      password: '',
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (formErrorMessage) {
      setOpen(true);
    }
  }, [formErrorMessage]);

  useEffect(() => {
    if (props.user.updateType === 'profile') {
      setUpdateConfirmModal(true);
    } else if (props.user.updateType === 'password') {
      setUpdateConfirmModal(true);
    } else if (props.user.updateType === 'signup') {
      setUpdateConfirmModal(true);
    }
  }, [props]);

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{
          // minHeight: '590px',
          // minWidth: '590px',
          height: isMobile ? '100%' : '620px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          borderRadius: 1,
          position: 'absolute',
          top: isMobile ? '' : '50%',
          msTransform: isMobile ? '' : 'translateY(-50%)',
          transform: isMobile ? '' : 'translateY(-50%)',
        }}
      >
        <Card elevation={5} sx={{ width: isMobile ? '100%' : '600px' }}>
          {!isMobile && (
            <CardMedia sx={{ height: '222px', width: '100%' }} image={loginBgImg}>
              <img
                src={logoRy1}
                alt="Rayence logo"
                style={{
                  position: 'relative',
                  float: 'right',
                  top: '12px',
                  right: '16px',
                }}
              />
            </CardMedia>
          )}
          <CardContent sx={{ padding: '20px 20px 0px 20px' }}>
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '40px',
                color: 'rgba(255,134,0,1.00)',
                marginBottom: '8px',
              }}
            >
              License Server
            </Typography>

            <CustomTextField
              size="small"
              margin="normal"
              fullWidth
              label="ID (Email)"
              {...register('email')}
              value={watch('email') || ''}
              inputProps={{
                maxLength: 135,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              autoFocus
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              sx={{ height: '64px', margin: '16px 0px 0px 0px' }}
            />
            <CustomTextField
              size="small"
              margin="normal"
              fullWidth
              label="Password"
              type={visiblePW ? 'text' : 'password'}
              {...register('password')}
              value={watch('password') || ''}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              inputProps={{
                maxLength: 135,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setVisiblePW(!visiblePW);
                    }}
                  >
                    {visiblePW && <VisibilityOffIcon />}
                    {!visiblePW && <VisibilityIcon />}
                  </IconButton>
                ),
              }}
              sx={{
                height: '64px',
                margin: '16px 0px 0px 0px',
              }}
            />

            <div>
              <FormControlLabel
                control={<Checkbox onChange={handleRememberMe} checked={rememberMe} />}
                label="Remember me"
              />
            </div>
          </CardContent>

          <CardActions sx={{ padding: '0px 20px 20px 20px' }}>
            <Grid container spacing={1}>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="error"
                  disabled={submitting}
                  style={{ textTransform: 'none' }}
                >
                  Login
                </Button>
              </Grid>
              {/* <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ textTransform: 'none' }}
                  onClick={onClickSignUp}
                >
                  SignUp
                </Button>
              </Grid> */}
            </Grid>
          </CardActions>
        </Card>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ErrorOutlineRoundedIcon color="warning" style={{ fontSize: 64 }} />
          <Typography id="modal-modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '28px' }}>
            {formErrorMessage}
          </Typography>

          <Button variant="contained" color="error" size="small" onClick={handleClose}>
            OK
          </Button>
        </Box>
      </Modal>

      <Modal
        open={updateConfirmModal}
        onClose={handleUpdateConfirmModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <CheckCircleOutlineRoundedIcon color="success" style={{ fontSize: 64 }} />
          {props.user.updateType !== 'signup' && (
            <Typography id="modal-modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '28px' }}>
              Update Successful
            </Typography>
          )}
          <Typography id="modal-modal-description" sx={{ mt: 2, pb: 2 }}>
            {props.user.updateType === 'profile'
              ? 'Please re-login to renew your profile'
              : props.user.updateType === 'password'
              ? 'Please re-login with your new password.'
              : props.user.updateType === 'signup'
              ? 'The form has been submitted successfully and the administrator will confirm and approve your request.'
              : ''}
          </Typography>
          <Button variant="contained" color="error" size="small" onClick={handleUpdateConfirmModalClose}>
            OK
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default LoginPage;
