import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Grid, Pagination, PaginationItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/material/styles';
import { setHistory } from '../../../redux/history';
import moment from 'moment';
import HistorySubSubComponent from './HistorySubSubComponent';
import FailModal from './../common/FailModal';
import { HISTORY_SERVER } from '../../../components/Config';
import { isEmptyObject, downloadLicenseHistoryXlsx } from './../../../utils/util';
import axios from 'axios';
axios.defaults.withCredentials = true;

const CustomGrid = styled(Grid)({
  marginBottom: '12px',
});

const HistorySubCompoent = (props) => {
  const dispatch = useDispatch();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [openFailModal, setOpenFailModal] = useState(false);

  const history = useSelector((state) => state.history.historyData);

  const modalHandleClose = () => {
    setOpenFailModal(false);
  };

  const column = [
    {
      sortable: true,
      field: 'no',
      headerName: 'No',
      flex: 0,
      width: 88,
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: '_group',
      headerName: 'Agency',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value.name}</div>
      ),
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    },
    {
      field: '_customer',
      headerName: 'Customer',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value}</div>
      ),
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: '_product',
      headerName: 'S/W',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value.name}</div>
      ),
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    },
    {
      field: 'options',
      headerName: 'Optional',
      headerAlign: 'center',
      flex: 3,
      // valueGetter: (params) => {
      //   return params ? params : '-';
      // },
      renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value ? params.value.join(', ') : '-'}
        </div>
      ),
      sortComparator: (v1, v2) => {
        const name1 = v1 || ''; // v1이 undefined일 경우 빈 문자열로 대체
        const name2 = v2 || ''; // v2가 undefined일 경우 빈 문자열로 대체
        return name1.join().localeCompare(name2.join());
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      headerAlign: 'center',
      align: 'center',
      flex: 1.5,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value}</div>
      ),
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: 'rent',
      headerName: 'Rent',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value ? params.value : '-'}
        </div>
      ),
      sortComparator: (v1, v2) => {
        const name1 = v1 || ''; // v1이 undefined일 경우 빈 문자열로 대체
        const name2 = v2 || ''; // v2가 undefined일 경우 빈 문자열로 대체
        return name1.localeCompare(name2);
      },
    },
    {
      field: '_publisher',
      headerName: 'Publisher',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value ? params.value.name : '-'}
        </div>
      ),
      sortComparator: (v1, v2) => {
        const name1 = v1?.name || ''; // v1이 undefined일 경우 빈 문자열로 대체
        const name2 = v2?.name || ''; // v2가 undefined일 경우 빈 문자열로 대체
        return name1.localeCompare(name2);
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {moment(params.value).format('YYYY.MM.DD HH:mm')}
        </div>
      ),
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
  ];

  function CustomPagination() {
    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={props.page}
        count={props.history ? props.history.totalPages : 0}
        onChange={(e, value) => {
          props.setPage(value);
        }}
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      />
    );
  }

  // const columnVisibilityModel = React.useMemo(() => {
  //   if (isEmptyObject(history)) {
  //     return {
  //       options: true,
  //       // date: true,
  //     };
  //   }
  //   return {
  //     options: false,
  //     // date: false,
  //   };
  // }, [history]);

  function handleSetSelectedRow(clickedRow) {
    props.history.docs.map((x, i) => {
      if (x._id === clickedRow) {
        dispatch(setHistory({ idx: i, historyData: x }));
      }
    });
  }

  const downloadXlsx = (rowcount) => {
    if (rowcount > 1000) {
      setOpenFailModal(true);
    } else if (rowcount > 0) {
      axios.post(`${process.env.REACT_APP_SERVER}${HISTORY_SERVER}/history`, props.searchParams).then((res) => {
        downloadLicenseHistoryXlsx(res.data.docs);
      });
    }
  };

  useEffect(() => {
    if (props.history && rowSelectionModel) {
      // console.log(props.history);
      if (rowSelectionModel) {
        handleSetSelectedRow(rowSelectionModel);
      }
    }
  }, [props.history]);

  return (
    <div>
      <Grid container>
        <CustomGrid item xs={12}>
          <div>
            <div style={{ float: 'left', fontSize: '16px', paddingLeft: '8px' }}>
              <b>{props.history && props.history.totalDocs}</b> Result(s)
            </div>

            <Button
              variant="contained"
              size="small"
              startIcon={<DownloadIcon />}
              style={{
                height: '100%',
                textTransform: 'none',
                float: 'right',
              }}
              disabled={!props.history || props.history.totalDocs === 0}
              onClick={() => {
                downloadXlsx(props.history.totalDocs);
              }}
            >
              Download
            </Button>
          </div>
        </CustomGrid>
        <CustomGrid item xs={isEmptyObject(history) ? 12 : 8} container>
          <Box
            sx={{
              height: '720px',
              maxHeight: '720px',
              width: '100%',
            }}
          >
            <DataGrid
              sx={{
                height: '100%',
                '& .MuiDataGrid-columnHeaderTitle ': {
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-columnHeader': {
                  fontSize: '15px',
                  backgroundColor: '#F3F3F3',
                  border: '1px #E2E6E9',
                },
                '& .MuiDataGrid-cell': {
                  borderColor: '#E2E6E9',
                  padding: '5px 10px 5px 10px',
                },
              }}
              getRowId={(row) => row['_id']}
              getRowHeight={() => 'auto'}
              columns={column}
              rows={props.history && props.history.docs}
              onRowSelectionModelChange={(ids) => {
                if (ids.length > 0) {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = props.history.docs.filter((row) => selectedIDs.has(row._id));
                  setRowSelectionModel(selectedRowData[0]._id);
                  dispatch(setHistory({ idx: [...selectedIDs][0], historyData: selectedRowData[0] }));

                  // handleSetSelectedRow(selectedRowData[0]._id);
                }
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{
                pagination: CustomPagination,
              }}
              pageSizeOptions={[5, 10, 20, 50]}
              pagination
              disableColumnSelector
              // columnVisibilityModel={columnVisibilityModel}
              showCellVerticalBorder
            />
          </Box>
        </CustomGrid>
        <CustomGrid item xs={isEmptyObject(history) ? 12 : 4} style={{ maxHeight: '800px' }}>
          {Object.keys(history).length > 0 && (
            <Grid
              sx={{
                width: '100%',
                height: '100%',
                padding: '8px',
                display: 'inline-flex',
                flexDirection: 'column',
                overflow: 'hidden',
                overflowY: 'scroll',
              }}
            >
              <HistorySubSubComponent rowClear={setRowSelectionModel} reloadList={props.reloadList} />
            </Grid>
          )}
        </CustomGrid>
      </Grid>
      <FailModal
        messageTitle={'The number of issues to download cannot exceed 1000.'}
        message={''}
        open={openFailModal}
        handleClose={modalHandleClose}
      />
    </div>
  );
};

export default HistorySubCompoent;
