import React, { useState } from 'react';
import { Button, Link, Grid, Modal, Typography, useMediaQuery } from '@mui/material';
import AboutModal from './AboutModal';

function Footer() {
  const matches = useMediaQuery('(max-width:1024px)');
  const [open, setOpen] = useState(false);

  //modal handler
  const modalHandleClose = (e) => {
    setOpen(false);
  };

  return (
    <div
      className="Footer"
      style={{
        height: '92px',
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'center',
        fontSize: '1rem',
        backgroundColor: '#222222',
        color: 'white',
        // bottom: matches ? '' : '0',
        width: '100%',
        padding: '4px 0px 4px 0px',
        // marginTop: '20px',
      }}
    >
      <Grid container style={{ padding: '8px 8px 8px 32px' }}>
        <Grid item xs={12}>
          <Typography variant="body2" color={'#999'}>
            <b style={{ color: 'white' }}>Address</b> 14, Samsung 1-ro 1-gil, Hwaseong-si, Gyeonggi-do, Korea (Zip
            18449)
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color={'white'}>
            <b style={{ color: 'white' }}>© Rayence</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <Link variant="body2" color={'#999999'}>
              {'About License Server'}
            </Link>
          </div>
        </Grid>
      </Grid>
      <AboutModal open={open} handleClose={modalHandleClose}></AboutModal>
    </div>
  );
}

export default Footer;
