import React, { useEffect, useState } from 'react';
import { Button, Box, Modal, Typography, TextField, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { EMAIL_SERVER } from '../../Config';

import SuccessModal from '../common/SuccessModal';
import FailModal from './../common/FailModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const ManageEmailModal = (props) => {
  const matches = useMediaQuery('(max-width:1024px)');
  // result message modal
  const [modalMessageTitle, setModalMessageTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openFailModal, setOpenFailModal] = useState(false);

  const resultModalHandleClose = (e) => {
    setModalMessageTitle('');
    setModalMessage('');
    setOpenSuccessModal(false);
    setOpenFailModal(false);
    setValue('email', '');
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email.').required('The data is empty.'),
  });

  const {
    handleSubmit,
    register,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: 'Add',
      email: '',
    },
    resolver: yupResolver(validationSchema),
  });

  //add or edit license type
  const onSubmit = async (data, e) => {
    axios.post(`${process.env.REACT_APP_SERVER}${EMAIL_SERVER}`, data).then((res) => {
      if (res.data.err) {
        setModalMessageTitle('Failed to Update');
        setModalMessage(res.data.msg);
        setOpenFailModal(true);
        props.handleClose();
      } else {
        setModalMessageTitle('Updated Successfully');
        setOpenSuccessModal(true);
        props.handleClose();
      }
    });
  };

  useEffect(() => {
    if (props.open) {
      clearErrors('email');
    }
    // eslint-disable-next-line
  }, [props.open]);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '28px' }}>
            Add a Recipient
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2, pb: 2 }} variant="subtitle1">
            Enter the email address you want to add.
          </Typography>
          <TextField
            size="small"
            inputProps={{
              maxLength: 60,
            }}
            {...register('email')}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            style={{ width: matches ? '75%' : '100%' }}
          />
          <div style={{ marginTop: '16px', justifyContent: 'center' }}>
            <Button
              size="small"
              variant="contained"
              color="error"
              sx={{ textTransform: 'none', marginRight: '24px' }}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              onClick={props.handleClose}
              sx={{ textTransform: 'none' }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>

      <SuccessModal messageTitle={modalMessageTitle} open={openSuccessModal} handleClose={resultModalHandleClose} />
      <FailModal
        messageTitle={modalMessageTitle}
        message={modalMessage}
        open={openFailModal}
        handleClose={resultModalHandleClose}
      />
    </div>
  );
};

export default ManageEmailModal;
