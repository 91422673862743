import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Container,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ManageLicenseTypeModal from './ManageLicenseTypeModal';
import ManageSWProductModal from './ManageSWProductModal';
import ManageOptionsModal from './ManageOptionsModal';
import ManageDeleteModal from './ManageDeleteModal';

import axios from 'axios';
import { PRODUCT_SERVER, GROUPTYPE_SERVER } from '../../Config';

const CustomButton = styled(Button)({
  textTransform: 'none',
});

function ProductManagePage() {
  const matches = useMediaQuery('(max-width:1024px)');
  const [groupTypeList, setGroupTypeList] = useState();
  const [seletedGroupTypeIndex, setSelectedGroupTypeIndex] = useState(0);

  const [swList, setSwList] = useState();
  const [selectSwIndex, setSelectedSwIndex] = useState(0);

  const [option, setOption] = useState();
  const [selectOptionIndex, setSelectOptionIndex] = useState(0);

  //licenseType modal 관련 state
  const [openLicenseTypeModal, setOpenLicenseTypeModal] = useState(false);
  const [licenseTypeModalProps, setLicenseTypeModalProps] = useState();

  //sw Product modal 관련 state
  const [openSWProductModal, setOpenSWProductModal] = useState(false);
  const [SWProductModalProps, setSWProductModalProps] = useState();

  //option modal 관련 state
  const [openOptionsModal, setOpenOptionsModal] = useState(false);

  //delete modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteModalProps, setDeleteModalProps] = useState();

  //modal handler
  const modalHandleClose = (e) => {
    // e.stopPropagation();
    setOpenLicenseTypeModal(false);
    setOpenSWProductModal(false);
    setOpenOptionsModal(false);
    setOpenDeleteModal(false);
    getGroupList();
  };

  //GroupTypeList Item Click
  const handleGroupTypeListItemClick = (event, index, value) => {
    setSelectedGroupTypeIndex(index);
    // setSelectedGroupType(value);
    //groupType 선택 시 sw list 갱신
    axios.get(`${process.env.REACT_APP_SERVER}${PRODUCT_SERVER}/${value._id}`).then((res) => {
      if (res.data.length > 0) {
        setSwList(res.data);
        setSelectedSwIndex(0);
        if (res.data[0].options) {
          sortOptions(res.data[0].options);
          setSelectOptionIndex(0);
        } else {
          setOption();
          setSelectOptionIndex();
        }
      } else {
        setSwList();
        setSelectedSwIndex();
        setOption();
        setSelectOptionIndex();
      }
    });
  };

  //swList Item Click
  const handleSWListItemClick = (event, index, value) => {
    setSelectedSwIndex(index);
    //sw List중 선택 시 option list default set
    setSelectOptionIndex(0);
    sortOptions(swList[index].options);
  };

  //option sorting
  const sortOptions = (options) => {
    let len = options.length;
    let freeOptions = [];
    let paidOptions = [];
    for (let i = 0; i < len; i++) {
      if (options[i].optionStatus) {
        if (options[i].optionType === 'free') {
          freeOptions.push(options[i]);
        } else {
          paidOptions.push(options[i]);
        }
      }
    }

    freeOptions = freeOptions.sort((a, b) => (a.optionName.toLowerCase() < b.optionName.toLowerCase() ? -1 : 1));
    paidOptions = paidOptions.sort((a, b) => (a.optionName.toLowerCase() < b.optionName.toLowerCase() ? -1 : 1));

    setOption([...paidOptions, ...freeOptions]);
  };

  //options Item Click
  const handleOptionListItemClick = (event, index, value) => {
    setSelectOptionIndex(index);
    // setSelectOption(value);
  };

  const getGroupList = () => {
    // grouptype list default load
    axios.get(`${process.env.REACT_APP_SERVER}${GROUPTYPE_SERVER}/type`).then((res) => {
      setGroupTypeList(res.data);
      setSelectedGroupTypeIndex(seletedGroupTypeIndex);
      // swList default load
      axios
        .get(`${process.env.REACT_APP_SERVER}${PRODUCT_SERVER}/${res.data[seletedGroupTypeIndex]._id}`)
        .then((res) => {
          if (res.data.length > 0) {
            setSwList(res.data);
            setSelectedSwIndex(0);
            if (res.data[0].options) {
              sortOptions(res.data[0].options);
              setSelectOptionIndex(0);
            } else {
              setOption();
              setSelectOptionIndex();
            }
          } else {
            setSwList();
            setSelectedSwIndex();
          }
        });
    });
  };

  useEffect(() => {
    // grouptype list default load
    axios.get(`${process.env.REACT_APP_SERVER}${GROUPTYPE_SERVER}/type`).then((res) => {
      setGroupTypeList(res.data);
      setSelectedGroupTypeIndex(0);
      // swList default load
      axios.get(`${process.env.REACT_APP_SERVER}${PRODUCT_SERVER}/${res.data[0]._id}`).then((res) => {
        setSwList(res.data);
        setSelectedSwIndex(0);

        if (res.data[0]) {
          if (res.data[0].options) {
            sortOptions(res.data[0].options);
          }
        }
      });
    });
  }, []);

  return (
    <div className="ManageProductPage">
      <Container
        maxWidth="lg"
        sx={matches ? { paddingTop: '116px', paddingBottom: '120px' } : { paddingTop: '188px', paddingBottom: '120px' }}
      >
        <Card elevation={1} sx={{ padding: '16px', mb: '16px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3} md={3}>
              <Typography
                variant="h4"
                fontWeight={500}
                sx={{
                  textAlign: 'left',
                  float: 'left',
                  color: 'rgba(197,197,197,1.00)',
                }}
              >
                License Type
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ marginLeft: '-1px' }} />
            <Grid item xs={12} sm={9} md={9}>
              <Grid item xs={12} container justifyContent="flex-end">
                <CustomButton
                  variant="contained"
                  style={{ margin: '4px 0px 4px 4px', fontSize: '0.72rem' }}
                  onClick={() => {
                    setLicenseTypeModalProps({
                      type: 'Add',
                      messageTitle: 'Enter the name of license type you want to add.',
                      id: groupTypeList[seletedGroupTypeIndex]._id,
                    });
                    setOpenLicenseTypeModal(true);
                  }}
                >
                  Add
                </CustomButton>
                <CustomButton
                  variant="contained"
                  style={{ margin: '4px 0px 4px 4px', fontSize: '0.72rem' }}
                  onClick={(e) => {
                    setLicenseTypeModalProps({
                      type: 'Edit',
                      messageTitle: 'Edit the name of license type to change.',
                      id: groupTypeList[seletedGroupTypeIndex]._id,
                      typeName: groupTypeList[seletedGroupTypeIndex].groupTypeName,
                    });
                    setOpenLicenseTypeModal(true);
                  }}
                >
                  Edit
                </CustomButton>
                <CustomButton
                  color="error"
                  variant="contained"
                  style={{
                    margin: '4px 0px 4px 4px',
                    fontSize: '0.72rem',
                  }}
                  onClick={(e) => {
                    // type : License, sw, option
                    setDeleteModalProps({
                      type: 'Delete',
                      modalType: 'License',
                      message: 'License Type',
                      id: groupTypeList[seletedGroupTypeIndex]._id,
                    });
                    setOpenDeleteModal(true);
                  }}
                >
                  Delete
                </CustomButton>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  border: '1px solid rgba(197,197,197,1.00)',
                  height: '186px',
                  overflow: 'hidden',
                  overflowY: 'scroll',
                }}
              >
                {groupTypeList && (
                  <List id="grouptype-list" disablePadding>
                    {groupTypeList &&
                      groupTypeList.map((v, i) => {
                        return (
                          <ListItemButton
                            dense
                            divider
                            key={v._id}
                            selected={seletedGroupTypeIndex === i}
                            onClick={(event) => handleGroupTypeListItemClick(event, i, v)}
                          >
                            <ListItemText
                              primary={i + 1 + 'ㅤㅤ' + v.groupTypeName}
                              style={{ whiteSpace: 'pre-wrap' }}
                            />
                          </ListItemButton>
                        );
                      })}
                  </List>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Card>

        <Card elevation={1} sx={{ padding: '16px', mb: '16px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3} md={3}>
              <Typography
                variant="h4"
                fontWeight={500}
                sx={{
                  textAlign: 'left',
                  float: 'left',
                  color: 'rgba(197,197,197,1.00)',
                }}
              >
                S/W Products
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ marginLeft: '-1px' }} />
            <Grid item xs={12} sm={9} md={9}>
              <Grid item xs={12} container justifyContent="flex-end">
                <CustomButton
                  variant="contained"
                  style={{ margin: '4px 0px 4px 4px', fontSize: '0.72rem' }}
                  onClick={(e) => {
                    setSWProductModalProps({
                      type: 'Add',
                      groupType: seletedGroupTypeIndex !== undefined ? groupTypeList[seletedGroupTypeIndex]._id : '',
                      product: selectSwIndex !== undefined ? swList[selectSwIndex] : '',
                    });
                    setOpenSWProductModal(true);
                  }}
                >
                  Add
                </CustomButton>
                <CustomButton
                  variant="contained"
                  style={{ margin: '4px 0px 4px 4px', fontSize: '0.72rem' }}
                  onClick={(e) => {
                    setSWProductModalProps({
                      type: 'Edit',
                      groupType: seletedGroupTypeIndex !== undefined ? groupTypeList[seletedGroupTypeIndex]._id : '',
                      product: selectSwIndex !== undefined ? swList[selectSwIndex] : '',
                    });
                    setOpenSWProductModal(true);
                  }}
                >
                  Edit
                </CustomButton>
                <CustomButton
                  color="error"
                  variant="contained"
                  style={{
                    margin: '4px 0px 4px 4px',
                    fontSize: '0.72rem',
                  }}
                  onClick={(e) => {
                    // type : License, sw, option
                    setDeleteModalProps({
                      type: 'Delete',
                      modalType: 'sw',
                      message: 'S/W Product',
                      id: swList[selectSwIndex]._id,
                    });

                    setOpenDeleteModal(true);
                  }}
                >
                  Delete
                </CustomButton>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  border: '1px solid rgba(197,197,197,1.00)',
                  height: '186px',
                  overflow: 'hidden',
                  overflowY: 'scroll',
                }}
              >
                {swList && (
                  <List id="sw-list" disablePadding>
                    {swList &&
                      swList.map((v, i) => {
                        return (
                          <ListItemButton
                            dense
                            divider
                            key={v._id}
                            selected={selectSwIndex === i}
                            onClick={(event) => handleSWListItemClick(event, i, v)}
                          >
                            <ListItemText primary={i + 1 + 'ㅤㅤ' + v.name} style={{ whiteSpace: 'pre-wrap' }} />
                          </ListItemButton>
                        );
                      })}
                  </List>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Card>

        <Card elevation={1} sx={{ padding: '16px', mb: '16px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3} md={3}>
              <Typography
                variant="h4"
                fontWeight={500}
                sx={{
                  textAlign: 'left',
                  float: 'left',
                  color: 'rgba(197,197,197,1.00)',
                }}
              >
                Options
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ marginLeft: '-1px' }} />
            <Grid item xs={12} sm={9} md={9}>
              <Grid item xs={12} container justifyContent="flex-end">
                <CustomButton
                  variant="contained"
                  style={{ margin: '4px 0px 4px 4px', fontSize: '0.72rem' }}
                  onClick={(e) => {
                    setSWProductModalProps({
                      type: 'Add',
                      product: swList[selectSwIndex],
                      option: option[selectOptionIndex],
                    });
                    setOpenOptionsModal(true);
                  }}
                >
                  Add
                </CustomButton>
                <CustomButton
                  variant="contained"
                  style={{ margin: '4px 0px 4px 4px', fontSize: '0.72rem' }}
                  onClick={(e) => {
                    setSWProductModalProps({
                      type: 'Edit',
                      product: swList[selectSwIndex],
                      option: option[selectOptionIndex],
                    });
                    setOpenOptionsModal(true);
                  }}
                >
                  Edit
                </CustomButton>
                <CustomButton
                  color="error"
                  variant="contained"
                  style={{
                    margin: '4px 0px 4px 4px',
                    fontSize: '0.72rem',
                  }}
                  onClick={(e) => {
                    // type : License, sw, option
                    setDeleteModalProps({
                      type: 'Delete',
                      modalType: 'option',
                      message: 'Option',
                      product: swList[selectSwIndex]._id,
                      optionName: option[selectOptionIndex].optionName,
                    });
                    setOpenDeleteModal(true);
                  }}
                >
                  Delete
                </CustomButton>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  border: '1px solid rgba(197,197,197,1.00)',
                  height: '240px',
                  overflow: 'hidden',
                  overflowY: 'scroll',
                }}
              >
                {swList && (
                  <List id="option-list" disablePadding>
                    {swList[selectSwIndex] &&
                      option &&
                      option.map((v, i) => {
                        return (
                          <ListItemButton
                            dense
                            divider
                            key={i}
                            selected={selectOptionIndex === i}
                            onClick={(event) => handleOptionListItemClick(event, i, v)}
                          >
                            <ListItemText primary={i + 1 + 'ㅤㅤ' + v.optionName} style={{ whiteSpace: 'pre-wrap' }} />
                            <div style={{ float: 'right' }}>{v.optionType !== 'paid' ? `Free` : ''}</div>
                          </ListItemButton>
                        );
                      })}
                  </List>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Card>

        <ManageLicenseTypeModal
          open={openLicenseTypeModal}
          handleClose={modalHandleClose}
          details={licenseTypeModalProps}
        />
        <ManageSWProductModal open={openSWProductModal} handleClose={modalHandleClose} details={SWProductModalProps} />
        <ManageOptionsModal open={openOptionsModal} handleClose={modalHandleClose} details={SWProductModalProps} />
        <ManageDeleteModal open={openDeleteModal} handleClose={modalHandleClose} details={deleteModalProps} />
      </Container>
    </div>
  );
}

export default ProductManagePage;
