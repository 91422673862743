import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Box,
  FormControl,
  Grid,
  InputLabel,
  FormLabel,
  Modal,
  NativeSelect,
  Typography,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { styled, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';

import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { PRODUCT_SERVER, GROUPTYPE_SERVER } from '../../Config';

import SuccessModal from '../common/SuccessModal';
import FailModal from '../common/FailModal';

const theme = createTheme({
  main: 'rgba(0, 0, 0, 0.6)',
  orange: orange[500],
});

const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const CustomFormControl = styled(FormControl)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const ManageSWProductModal = (props) => {
  const matches = useMediaQuery('(max-width:1024px)');
  const [groupTypeList, setGroupTypeList] = useState();

  // result message modal
  const [modalMessageTitle, setModalMessageTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openFailModal, setOpenFailModal] = useState(false);

  const user = useSelector((state) => state.user);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: matches ? '100%' : '50%',
    maxWidth: 400,
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
  };

  const resultModalHandleClose = (e) => {
    setModalMessage('');
    setOpenSuccessModal(false);
    setOpenFailModal(false);
  };
  const wordRegex = /^(?=.*\S)[A-Za-z0-9~!@#$%^&*()_+=,.? ]*[A-Za-z0-9~!@#$%^&*()_+=,.?]$/u;
  const validationSchema = Yup.object().shape({
    groupType: Yup.string().required('This data is empty.'),
    requestPassKey: Yup.string().required('This data is empty.'),
    activationPassKey: Yup.string().required('This data is empty.'),
    name: Yup.string()
      .required('This data is empty.')
      .matches(wordRegex, {
        message: `Only English alphabets, numbers, and some special characters can be entered.
Allowed special characters: ~ ! @ # $ % ^ & * ( ) _ - + = , . ?`,
      }),
    optionString: Yup.string().required('This data is empty.'),
  });

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: '',
      name: '',
      groupType: '',
      requestPassKey: '',
      activationPassKey: '',
      optionString: '',
      status: true,
    },
    resolver: yupResolver(validationSchema),
  });

  //add or edit product
  const onSubmit = async (data, e) => {
    let checkRegex = false;
    checkRegex = checkWordRegex('name', data.name);
    checkRegex = checkWordRegex('requestPassKey', data.requestPassKey) || checkRegex ? true : false;
    checkRegex = checkWordRegex('activationPassKey', data.activationPassKey) || checkRegex ? true : false;
    checkRegex = checkWordRegex('optionString', data.optionString, true) || checkRegex ? true : false;

    if (!checkRegex) {
      axios.post(`${process.env.REACT_APP_SERVER}${PRODUCT_SERVER}`, data).then((res) => {
        if (res.data.err) {
          setModalMessageTitle('Failed to Update');
          setModalMessage(res.data.msg);
          setOpenFailModal(true);
          props.handleClose();
        } else {
          setModalMessageTitle('Updated Successfully');
          setOpenSuccessModal(true);
          props.handleClose();
        }
      });
    }
  };

  function checkWordRegex(fieldName, string, option = false) {
    let wordRegex = /^(?=.*\S)[A-Za-z0-9`~!@#$%^&*()-_=+.,?' ]*[A-Za-z0-9`~!@#$%^&*()-_=+.,?']$/u;
    let optionStringRegex =
      /^`_(?:[0-9]+|\$\{[a-zA-Z0-9]+\})(?:;(?:[0-9]+|\$\{[a-zA-Z0-9]+\}))*;?_`$|^`_(?:[0-9]+|\$\{[a-zA-Z0-9]+\})(?:;(?:[0-9]+|\$\{[a-zA-Z0-9]+\}))*;?`$/g;

    if (option) {
      if (!optionStringRegex.test(string)) {
        setError(fieldName, {
          type: 'custom',
          message: 'You must write the optional string form.',
        });
        return true;
      } else {
        return false;
      }
    } else if (!wordRegex.test(string)) {
      setError(fieldName, {
        type: 'custom',
        message: "Only letters, numbers and punctuations (`~!@#$%^&*()-_=+.,?') are allowed.",
      });
      return true;
    } else {
      return false;
    }
  }
  useEffect(() => {
    if (props.open) {
      // react hook form clear
      reset();
      if (props.details !== undefined) {
        axios.get(`${process.env.REACT_APP_SERVER}${GROUPTYPE_SERVER}/type`).then((res) => {
          setGroupTypeList(res.data);

          setValue('groupType', props.details.groupType);
          setValue('type', props.details.type);
          if (props.details.type === 'Edit') {
            let product = props.details.product;
            setValue('id', product._id);
            setValue('name', product.name);
            setValue('requestPassKey', product.requestPassKey);
            setValue('activationPassKey', product.activationPassKey);
            setValue('optionString', product.optionString);
          }
        });
      }
    }
  }, [props.open]);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          overflow: 'scroll',
        }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '28px' }}>
            {props.details && props.details.type} a S/W Product
          </Typography>

          <Grid item container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <CustomFormControl
                type="text"
                variant="standard"
                style={{ width: matches ? '90%' : '100%', height: '40px' }}
              >
                <InputLabel shrink htmlFor="groupType">
                  <span>
                    <b style={{ color: 'red' }}>* </b>
                    License Type
                  </span>
                </InputLabel>
                <NativeSelect
                  id="groupType"
                  fullWidth
                  size="small"
                  {...register('groupType')}
                  value={watch('groupType') || ''}
                >
                  {user.userData.role !== 'user' && <option value={'None'}>Select a GroupType...</option>}
                  {groupTypeList &&
                    groupTypeList.map((v, i) => {
                      return (
                        <option key={v._id} value={v._id}>
                          {v.groupTypeName}
                        </option>
                      );
                    })}
                </NativeSelect>
              </CustomFormControl>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                variant="standard"
                label={
                  <span>
                    <b style={{ color: 'red' }}>* </b>
                    S/W Name
                  </span>
                }
                autoComplete="off"
                // inputProps={{ maxLength: 135 }}
                {...register('name')}
                value={watch('name')}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
                size="small"
                style={{ width: matches ? '90%' : '100%' }}
              />
            </Grid>
            <Grid item xs={12}>
              <div>{Boolean(errors.requestPassKey)}</div>
              <CustomTextField
                variant="standard"
                label={
                  <span>
                    <b style={{ color: 'red' }}>* </b>
                    Request Pass Key
                  </span>
                }
                autoComplete="requestPassKey"
                // inputProps={{ maxLength: 135 }}
                {...register('requestPassKey')}
                value={watch('requestPassKey')}
                error={Boolean(errors.requestPassKey)}
                helperText={errors.requestPassKey?.message}
                size="small"
                style={{ width: matches ? '90%' : '100%' }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                variant="standard"
                label={
                  <span>
                    <b style={{ color: 'red' }}>* </b>
                    Activation Pass Key
                  </span>
                }
                autoComplete="off"
                // inputProps={{ maxLength: 135 }}
                {...register('activationPassKey')}
                value={watch('activationPassKey')}
                error={Boolean(errors.activationPassKey)}
                helperText={errors.activationPassKey?.message}
                size="small"
                style={{ width: matches ? '90%' : '100%' }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomFormControl type="text" variant="standard" style={{ width: matches ? '90%' : '100%' }}>
                <FormLabel htmlFor={'optionString'} style={{ textAlign: 'left' }}>
                  Option String
                </FormLabel>
                <span style={{ textAlign: 'left', fontWeight: 'bold', color: 'red' }}>
                  The Option Param name must match with the Option Name. ignoring spaces. It is case-sensitive. (ex.
                  Option A 1)
                </span>
                <TextField
                  id="optionString"
                  multiline
                  rows={6}
                  variant="standard"
                  autoComplete="off"
                  {...register('optionString')}
                  value={watch('optionString')}
                  error={Boolean(errors.optionString)}
                  helperText={errors.optionString?.message}
                  size="small"
                  placeholder="`_${OptionA1}`"
                />
              </CustomFormControl>
            </Grid>

            <Grid item xs={12}>
              <div style={{ marginTop: '16px', justifyContent: 'center' }}>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="error"
                  sx={{ textTransform: 'none', marginRight: '24px' }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="inherit"
                  onClick={props.handleClose}
                  sx={{ textTransform: 'none' }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <SuccessModal messageTitle={modalMessageTitle} open={openSuccessModal} handleClose={resultModalHandleClose} />
      <FailModal
        messageTitle={modalMessageTitle}
        message={modalMessage}
        open={openFailModal}
        handleClose={resultModalHandleClose}
      />
    </div>
  );
};

export default ManageSWProductModal;
