import React, { useEffect, useState, forwardRef, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Modal,
  NativeSelect,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
//date picker
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getYear } from 'date-fns';
import _ from 'lodash';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SearchIcon from '@mui/icons-material/Search';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { styled, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import HistorySubCompoent from './HistorySubCompoent';
import { resetHistory } from '../../../redux/history';
import { HISTORY_SERVER } from '../../../components/Config';
import { useForm } from 'react-hook-form';
import axios from 'axios';
axios.defaults.withCredentials = true;

const CustomGrid = styled(Grid)({
  minHeight: '52px',
});

const CustomGrid2 = styled(Grid)({
  padding: '16px 16px 0px 16px',
  // marginBottom: '4px',
});

const theme = createTheme({
  main: 'rgba(0, 0, 0, 0.6)',
  orange: orange[500],
});

const customerErrorModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const CustomFormControl = styled(FormControl)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const CustomRadio = styled(Radio)({
  width: '32px',
  height: '32px',
  marginLeft: '12px',
});

function HistoryPage() {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width:1024px)');
  const [swList, setSwList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [history, setHistory] = useState();
  const [page, setPage] = useState(1);
  const hasMounted = useRef(false);
  // const [total, setTotal] = useState();
  const [errorMsgModalOpen, setErrorMsgModalOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  //date picker
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const years = _.range(2000, getYear(new Date()) + 1, 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const user = useSelector((state) => state.user);

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      variant="outlined"
      onClick={onClick}
      ref={ref}
      disabled={watch('date') !== 'daterange'}
      size="small"
      style={{ width: '220px' }}
      startIcon={<CalendarMonthIcon />}
    >
      {value}
    </Button>
  ));

  const handleErrorMsgModalClose = () => {
    setErrorMsg('');
    setErrorMsgModalOpen(false);
  };

  const {
    handleSubmit,
    register,
    setValue,
    watch,

    // formState: { errors },
  } = useForm({
    defaultValues: {
      agency: 'None',
      customer: '',
      sw: 'None',
      type: 'None',
      date: 'All',
      daterange: '',
      page: page,
      isPagination: true,
    },
    // resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data, e) => {
    data.page = 1;
    // async request which may result error
    try {
      let specialRule = /([`~!@#$%^&*()-_=+?.,?'\s])/gi;
      let wordRegex = /^[\p{L}0-9]+$/u;
      let copyCustomer = data.customer;
      let replaceCustomer = copyCustomer.replace(specialRule, '');
      if (replaceCustomer === '' ? false : !wordRegex.test(replaceCustomer)) {
        setErrorMsg(`Only letters, numbers and punctuations (\`~!@#$%^&*()-_=+.,?') are allowed.`);
        setErrorMsgModalOpen(true);
      }
      // 조회 조건 param으로 request 요청
      else {
        //검색 날짜조건범위 로컬기준 날짜값으로 수정
        if (data.daterange[0]) {
          data.daterange[0] = `${data.daterange[0].getFullYear()}-${(data.daterange[0].getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${data.daterange[0].getDate().toString().padStart(2, '0')}`;
        }
        if (data.daterange[1]) {
          data.daterange[1] = `${data.daterange[1].getFullYear()}-${(data.daterange[1].getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${data.daterange[1].getDate().toString().padStart(2, '0')}`;
        }

        data.clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        await axios.post(`${process.env.REACT_APP_SERVER}${HISTORY_SERVER}/history`, data).then((res) => {
          setHistory(res.data);
          // setTotal(res.data.totalDocs);
          // search 버튼 누를때, customer 수정저장 할때 구분처리
          if (e !== undefined) {
            dispatch(resetHistory());
          }
        });
      }
    } catch (e) {
      // handle your error
    }
  };

  useEffect(() => {
    //sw List DB로 부터 가져오기
    if (user.userData.hasOwnProperty('isAuth')) {
      // user group 적용 여부 체크
      if (user.userData.group !== undefined) {
        axios
          .get(`${process.env.REACT_APP_SERVER}${HISTORY_SERVER}/groups`)
          .then((res) => {
            // console.log(res.data);
            setGroupList(res.data);
            return res;
          })
          .then((res) => {
            if (user.userData.role === 'user') {
              setValue('agency', res.data[0]._id);
            }
          });

        axios.get(`${process.env.REACT_APP_SERVER}${HISTORY_SERVER}/products`).then((res) => {
          setSwList(res.data);
          dispatch(resetHistory());
        });

        //페이지 최초 로딩
        axios
          .post(`${process.env.REACT_APP_SERVER}${HISTORY_SERVER}/history`, {
            agency: user.userData.role === 'user' ? user.userData.group._id : 'None',
            customer: '',
            sw: 'None',
            type: 'None',
            date: 'All',
            daterange: '',
            page: 1,
            isPagination: true,
          })
          .then((res) => {
            setHistory(res.data);
          });
      }
    }
  }, []);

  // daterange 이외 항목 라디오버튼 선택 -> daterange clear
  useEffect(() => {
    setDateRange([null, null]);
    setValue('daterange', [null, null]);
  }, [watch('date')]);

  //첫 랜더링 시 page state에 따른 history 조회 방지
  useEffect(() => {
    hasMounted.current = false;

    return () => {};
  }, []);

  useEffect(() => {
    if (hasMounted.current) {
      searchPage();
    } else {
      hasMounted.current = true;
    }
  }, [page]);

  const searchPage = () => {
    let data = {
      agency: watch('agency'),
      customer: watch('customer'),
      sw: watch('sw'),
      type: watch('type'),
      date: watch('date'),
      daterange: watch('daterange'),
      page: page,
      isPagination: true,
    };
    //검색 날짜조건범위 로컬기준 날짜값으로 수정
    if (data.daterange[0]) {
      data.daterange[0] = `${data.daterange[0].getFullYear()}-${(data.daterange[0].getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${data.daterange[0].getDate().toString().padStart(2, '0')}`;
    }
    if (data.daterange[1]) {
      data.daterange[1] = `${data.daterange[1].getFullYear()}-${(data.daterange[1].getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${data.daterange[1].getDate().toString().padStart(2, '0')}`;
    }
    axios.post(`${process.env.REACT_APP_SERVER}${HISTORY_SERVER}/history`, data).then((res) => {
      setHistory(res.data);
    });
  };

  return (
    <div className="HistoryPage">
      {user.userData.group !== undefined && (
        <Grid>
          <Grid
            container
            sx={
              matches
                ? { paddingTop: '100px', backgroundColor: '#e0e0e0' }
                : {
                    paddingTop: '148px',
                    backgroundColor: '#e0e0e0',
                  }
            }
          >
            <Grid item xs={12} container sx={{ border: '1px solid  #757575' }}>
              <Grid
                item
                container
                xs={12}
                md={10}
                sx={{
                  height: '100%',
                  padding: '16px 8px 8px 16px',
                }}
              >
                <Grid item container direction="row" spacing={1}>
                  <CustomGrid item xs={12} md={4}>
                    <CustomFormControl type="text" variant="standard" fullWidth sx={{ height: '40px' }}>
                      <InputLabel shrink htmlFor="Agency">
                        Agency
                      </InputLabel>
                      <NativeSelect id="Agency" fullWidth size="small" {...register('agency')} defaultValue="None">
                        {user.userData.role !== 'user' && <option value={'None'}>Select a Agency...</option>}
                        {groupList &&
                          groupList.map((v, i) => {
                            return (
                              <option key={v._id} value={v._id}>
                                {v.name}
                              </option>
                            );
                          })}
                      </NativeSelect>
                    </CustomFormControl>
                  </CustomGrid>
                  <CustomGrid item xs={12} md={4}>
                    <CustomFormControl theme={theme} variant="standard" size="small" fullWidth>
                      <InputLabel shrink htmlFor="sw">
                        S/W
                      </InputLabel>
                      {swList && (
                        <NativeSelect id="sw" label="S/W" fullWidth {...register('sw')}>
                          <option value={'None'}>Select a Software...</option>
                          {swList &&
                            swList.map((v, i) => {
                              return (
                                <option key={v._id} value={v._id}>
                                  {v.name}
                                </option>
                              );
                            })}
                        </NativeSelect>
                      )}
                    </CustomFormControl>
                  </CustomGrid>
                  <CustomGrid item xs={12} md={4}>
                    <CustomFormControl variant="standard" size="small" fullWidth>
                      <InputLabel shrink htmlFor="type">
                        Type
                      </InputLabel>
                      <NativeSelect id="type" label="Type" fullWidth {...register('type')}>
                        <option value={'None'}>Select a Type...</option>
                        <option value={'New'}>New</option>
                        <option value={'ReIssued'}>ReIssued</option>
                        <option value={'Waiting for Approval'}>Waiting for Approval</option>
                      </NativeSelect>
                    </CustomFormControl>
                  </CustomGrid>

                  <CustomGrid item xs={12} md={4}>
                    <CustomFormControl fullWidth variant="standard">
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Customer"
                        autoComplete="customer"
                        inputProps={{ maxLength: 135 }}
                        {...register('customer')}
                        size="small"
                        sx={{ color: 'red' }}
                      />
                    </CustomFormControl>
                  </CustomGrid>
                  <CustomGrid item xs={12} md={8}>
                    <CustomFormControl>
                      <FormLabel id="date" sx={{ fontSize: '0.8rem' }}>
                        Search By Date
                      </FormLabel>
                      <RadioGroup id="date" name="radio-buttons-group" defaultValue="All" row>
                        <FormControlLabel
                          value="All"
                          control={<CustomRadio size="small" />}
                          label="All"
                          {...register('date')}
                        />
                        <FormControlLabel
                          value="Day"
                          control={<CustomRadio size="small" />}
                          label="Day"
                          {...register('date')}
                        />
                        <FormControlLabel
                          value="Week"
                          control={<CustomRadio size="small" />}
                          label="Week"
                          {...register('date')}
                        />
                        <FormControlLabel
                          value="Month"
                          control={<CustomRadio size="small" />}
                          label="Month"
                          {...register('date')}
                        />
                        <FormControlLabel
                          value="daterange"
                          control={<CustomRadio size="small" />}
                          {...register('date')}
                        ></FormControlLabel>
                        <DatePicker
                          dateFormat="yyyy/MM/dd"
                          customInput={<CustomInput />}
                          placeholderText={'From date to date'}
                          disabled={watch('date') !== 'daterange'}
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          minDate={new Date(2000, 0, 1)}
                          maxDate={new Date(getYear(new Date()), 11, 31)}
                          onChange={(update) => {
                            setDateRange(update);
                            setValue('daterange', update);
                          }}
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                {'<'}
                              </button>
                              <select
                                value={getYear(date)}
                                onChange={({ target: { value } }) => {
                                  changeYear(value);
                                }}
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <select
                                value={months[date.getMonth(date)]}
                                onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                {'>'}
                              </button>
                            </div>
                          )}
                        />
                      </RadioGroup>
                    </CustomFormControl>
                  </CustomGrid>
                  <CustomGrid item xs={12} style={matches ? { display: 'flex' } : { display: 'none' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      onClick={handleSubmit(onSubmit)}
                      fullWidth
                      sx={{ height: '100%', fontSize: 20, textTransform: 'none' }}
                    >
                      <SearchIcon sx={{ fontSize: 32 }} />
                      Search
                    </Button>
                  </CustomGrid>
                </Grid>
              </Grid>
              <CustomGrid
                item
                xs={12}
                md={2}
                style={matches ? { display: 'none' } : { padding: '24px', display: 'flex' }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="error"
                  onClick={handleSubmit(onSubmit)}
                  fullWidth
                  sx={{ height: '100%', fontSize: 20, textTransform: 'none' }}
                >
                  <SearchIcon sx={{ fontSize: 32 }} />
                  Search
                </Button>
              </CustomGrid>
            </Grid>
          </Grid>
          <CustomGrid2
            item
            xs={12}
            style={{
              // display: 'inline-flex',
              // flexDirection: 'column',
              // overflow: 'hidden',
              // overflowY: 'scroll',
              backgroundColor: 'white',
              paddingBottom: '100px',
              width: '100%',
            }}
          >
            <HistorySubCompoent
              history={history}
              reloadList={searchPage}
              page={page}
              setPage={setPage}
              searchParams={{
                agency: watch('agency'),
                customer: watch('customer'),
                sw: watch('sw'),
                type: watch('type'),
                date: watch('date'),
                daterange: watch('daterange'),
                page: 1,
                isPagination: false,
              }}
            />
          </CustomGrid2>
        </Grid>
      )}
      <Modal
        open={errorMsgModalOpen}
        onClose={handleErrorMsgModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={customerErrorModalstyle}>
          <ErrorOutlineRoundedIcon color="warning" style={{ fontSize: 64 }} />
          <Typography id="modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '24px' }}>
            {errorMsg}
          </Typography>
          <Button variant="contained" color="error" size="small" onClick={handleErrorMsgModalClose}>
            OK
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default HistoryPage;
