import React, { useEffect } from 'react';
import { Grid, useMediaQuery } from '@mui/material';

import GroupLicenseBox from './GroupLicenseBox';
import GEDelphiBox from './GEDelphiBox';

function OtherPage() {
  const matches = useMediaQuery('(max-width:1024px)');

  useEffect(() => {
    //sw List DB로 부터 가져오기
  }, []);

  return (
    <div className="LicensePage">
      <Grid
        // component={Container}
        // maxWidth="lg"
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
        sx={matches ? { paddingTop: '116px', paddingBottom: '120px' } : { paddingTop: '188px', paddingBottom: '120px' }}
      >
        <Grid item xs={12} md={5}>
          <GroupLicenseBox />
        </Grid>
        <Grid item xs={12} md={5}>
          <GEDelphiBox />
        </Grid>
      </Grid>
    </div>
  );
}

export default OtherPage;
