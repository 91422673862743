import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Modal,
  TextField,
  TextareaAutosize,
  Typography,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import KeyIcon from '@mui/icons-material/Key';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { styled } from '@mui/material/styles';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { Parser } from '@json2csv/plainjs';
import { string as stringFormatter } from '@json2csv/formatters';
import { downloadLicenseFile } from '../../../utils/util';
import { HISTORY_SERVER, License_SERVER } from '../../Config';
import { resetHistory } from '../../../redux/history';
import axios from 'axios';
import SuccessModal from './../common/SuccessModal';
import FailModal from './../common/FailModal';
axios.defaults.withCredentials = true;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 460,
  bgcolor: 'rgb(253,237,237)',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const CustomTitleTypography = styled(Typography)({
  color: '#7E7F7F',
});
const CustomDetailTypography = styled(Typography)({
  fontWeight: 'bold',
});
const CustomGrid = styled(Grid)({
  marginBottom: '8px',
  wordWrap: 'break-word',
});

const CustomButton = styled(Button)({
  minWidth: '16px',
  marginRight: '4px',
  textTransform: 'none',
  color: 'white',
  backgroundColor: '#bdbdbd',
  ':hover': { backgroundColor: '#616161' },
});

const HistorySubSubComponent = (props) => {
  const [open, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openFailModal, setOpenFailModal] = useState(false);

  const [codeValidModalOpen, setCodeValidModalOpen] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [customerNameError, setCustomerNameError] = useState(false);
  const [edit, setEdit] = useState(false);
  const user = useSelector((state) => state.user);
  const history = useSelector((state) => state.history.historyData);
  const dispatch = useDispatch();

  const resultDeleteModalHandleClose = (e) => {
    setDeleteModalOpen(false);
    setModalMessage('');
    setOpenSuccessModal(false);
    setOpenFailModal(false);
  };

  const resultDeleteModalSuccessHandleClose = (e) => {
    setDeleteModalOpen(false);
    setModalMessage('');
    setOpenSuccessModal(false);
    setOpenFailModal(false);
    dispatch(resetHistory());
  };

  const handleCodeValidModalClose = () => {
    setFormErrorMessage('');
    setCodeValidModalOpen(false);
  };

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    // formState: { errors },
  } = useForm({
    defaultValues: {
      // customer: props.history.customer,
      // sw: props.history.product,
      // options: '',
      requestCode: '',
      // licenseCode: props.history.licenseCode,
      // type: props.history.type,
    },
    // resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data, e) => {
    if (data.requestCode !== '' && data.requestCode !== undefined) {
      let dataToSubmit = {
        customer: history._customer,
        sw: history._product._id,
        group: history._group,
        publisher: history._publisher,
        requestCode: data.requestCode,
        type: 'Waiting for Approval',
        rent: history.rent,
      };
      dataToSubmit.userId = user.userData._id;
      //option 정렬
      dataToSubmit['options'] = [];
      if (history.options) {
        history.options.map((v, i) => {
          dataToSubmit['options'].push(v);
          dataToSubmit[v] = true;
        });
      }

      // console.log(dataToSubmit);
      try {
        axios.post(`${process.env.REACT_APP_SERVER}${License_SERVER}`, dataToSubmit).then((res) => {
          // console.log(res.data.licenseKey);
          // console.log(res.data);
          if (res.data.error) {
            // enqueueSnackbar(res.data.error, {
            //   variant: 'error',
            //   autoHideDuration: 4000,
            // });
            setFormErrorMessage(res.data.error);
            setCodeValidModalOpen(true);
          } else {
            //modal close & textfield clear
            setValue('requestCode', '');
            setOpen(false);
          }
        });
      } catch (e) {}
    } else {
      setFormErrorMessage('Please enter the request code.');
      setCodeValidModalOpen(true);
      // enqueueSnackbar('Please enter the request code.', {
      //   variant: 'warning',
      //   autoHideDuration: 2000,
      // });
    }
  };

  const modalHandleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const modalHandleClose = (e) => {
    e.stopPropagation();
    //license 발급 modal close 시 requestCode textfield clear
    setValue('requestCode', '');
    setOpen(false);
  };

  const clickAccept = async () => {
    // let formData = { _id: props.history._id, type: 'Waiting for Approval' };
    let formData = { _id: history._id, type: 'ReIssued' };
    await axios.put(`${process.env.REACT_APP_SERVER}${HISTORY_SERVER}/history`, formData).then((res) => {
      props.reloadList();
      enqueueSnackbar('Histoy type updated.', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    });
  };

  const clickReject = async () => {
    let formData = { _id: history._id, type: 'Rejected' };
    await axios.put(`${process.env.REACT_APP_SERVER}${HISTORY_SERVER}/history`, formData).then((res) => {
      props.reloadList();
      enqueueSnackbar('Histoy type updated.', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    });
  };

  const formCSVLicense = (data, downloadOption = true) => {
    // console.log(data);

    // MyVet Equine SW 예외처리
    if (data.sw === 'MyVet Equine SW') {
      let output = '[LICENSE_INFO]\nACTIVATION_CODE=';
      output += data.licenseCode;

      let filename = 'MyVetEquineSW.lic';

      let blob = new Blob([output], { type: 'text' });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        let link = document.createElement('a');

        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          let url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      return;
    } else {
      // let fields = ['group', 'customer', 'software', 'optionLicense', 'requestCode', 'licenseKey'];
      // let fieldNames = ['Agency', 'Customer', 'S/W', 'Option', 'Request Code', 'License Key'];
      let exportData = {};
      exportData.Agency = data._group.name;
      exportData.Customer = data._customer;
      exportData['S/W'] = data._product.name;
      let optionLicenseString = '';

      for (let i = 0; i < data.options.length; i++) {
        if (data.options[i]) {
          if (optionLicenseString === '') {
            optionLicenseString = data.options[i];
          } else {
            optionLicenseString += ', ' + data.options[i];
          }
        }
      }

      if (optionLicenseString === '') {
        optionLicenseString = 'none';
      }

      exportData.Option = optionLicenseString;
      exportData['Request Code'] = data.requestCode;
      exportData['License Key'] = data.licenseCode;
      // console.log(exportData);
      const opts = {
        data: exportData,
        delimiter: '\t',
        formatters: {
          // Never use quotes
          string: stringFormatter({ quote: '' }),
        },
      };
      const parser = new Parser(opts);
      const csv = parser.parse(exportData);

      // console.log(csv);
      // dispatch(addLicense(exportData));
      if (downloadOption) {
        downloadLicenseFile(csv);
      }
    }
  };

  const saveCustomer = async () => {
    // if (history._customer !== watch('customer')) {}
    let formData = { _id: history._id, type: 'ModifyCustomer', customer: watch('customer') };
    const nameRegExp = /^(?=.*\S)[\p{L}\p{N}~!@#$%^&*()\-_+=?.,? '\s]*$|^(?!\s*$).*$ /u;

    if (nameRegExp.test(watch('customer'))) {
      await axios.put(`${process.env.REACT_APP_SERVER}${HISTORY_SERVER}/history`, formData).then((res) => {
        enqueueSnackbar('Histoy customer updated.', {
          variant: 'success',
          autoHideDuration: 2000,
        });
        setEdit(false);
        props.reloadList();
        // props.rowClear([]);
        setCustomerNameError(false);
      });
    } else {
      setCustomerNameError(true);
    }
  };

  const handleChangeCustomer = (v) => {
    const nameRegExp = /^(?=.*\S)[\p{L}\p{N}~!@#$%^&*()\-_+=?.,? '\s]*$|^(?!\s*$).*$ /u;
    if (!nameRegExp.test(v)) {
      setCustomerNameError(true);
    } else {
      setCustomerNameError(false);
    }
  };

  // history delete
  const deleteCustomer = async () => {
    let formData = { _id: history._id, type: 'DeleteHistory' };
    await axios.put(`${process.env.REACT_APP_SERVER}${HISTORY_SERVER}/history`, formData).then((res) => {
      if (res.data === 'ok') {
        props.reloadList();
        props.rowClear([]);
        setModalMessage('Updated Successfully');
        setOpenSuccessModal(true);
        setEdit(false);
      } else {
        setModalMessage('Failed to Update');
        setOpenFailModal(true);
        props.reloadList();
      }
    });
  };

  useEffect(() => {
    // dispatch(resetHistory());

    // setValue('customer', history._customer);
    setEdit(false);
    setCustomerNameError(false);
  }, [props]);

  return (
    <>
      {Object.keys(history).length > 0 && (
        <Grid container style={{ backgroundColor: '#e0e0e0', padding: '8px' }}>
          <Grid item xs={12}>
            <CustomButton
              onClick={() => {
                dispatch(resetHistory());
                props.rowClear([]);
              }}
            >
              <ArrowForwardIcon />
            </CustomButton>
          </Grid>
          <Grid item xs={12}>
            {edit && (
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  autoFocus
                  defaultValue={history._customer}
                  {...register('customer')}
                  onChange={(e) => {
                    handleChangeCustomer(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <CustomButton color="secondary" variant="contained" size="small" onClick={saveCustomer}>
                        <CheckIcon />
                      </CustomButton>

                      <CustomButton
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setEdit(false);
                          setCustomerNameError(false);
                        }}
                      >
                        <CloseIcon />
                      </CustomButton>
                    </InputAdornment>
                  }
                  inputProps={{
                    maxLength: 135,
                  }}
                  error={customerNameError}
                />
                {customerNameError && (
                  <FormHelperText error>
                    {"Only letters, numbers and punctuations (`~!@#$%^&*()-_=+.,?') are allowed."}
                  </FormHelperText>
                )}
              </FormControl>
            )}
            {!edit && (
              <Typography variant="h5" fontWeight={600} sx={{ paddingBottom: '16px' }}>
                {history._customer}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <CustomTitleTypography>Agency</CustomTitleTypography>
          </Grid>
          <CustomGrid item xs={12}>
            <CustomDetailTypography>{history._group ? history._group.name : ''}</CustomDetailTypography>
          </CustomGrid>
          <Grid item xs={12}>
            <CustomTitleTypography>Request Code</CustomTitleTypography>
          </Grid>
          <CustomGrid item xs={12}>
            <CustomDetailTypography>{history.requestCode}</CustomDetailTypography>
          </CustomGrid>
          <Grid item xs={12}>
            <CustomTitleTypography>License Code</CustomTitleTypography>
          </Grid>
          <CustomGrid item xs={12}>
            <TextareaAutosize
              id="licenseCode"
              value={history.type === 'Rejected' ? 'ㅤ' : history.licenseCode}
              readOnly
              style={{
                width: '95%',
                color: history.type === 'Waiting for Approval' ? 'red' : '',
              }}
            ></TextareaAutosize>
          </CustomGrid>
          <Grid item xs={12}>
            <CustomTitleTypography>S/W</CustomTitleTypography>
          </Grid>
          <CustomGrid item xs={12}>
            <CustomDetailTypography>{history._product.name}</CustomDetailTypography>
          </CustomGrid>
          <Grid item xs={12}>
            <CustomTitleTypography>Optional</CustomTitleTypography>
          </Grid>
          <CustomGrid item xs={12}>
            <CustomDetailTypography>
              {history.options &&
                history.options.map((v, i) => {
                  let len = history.options.length;
                  if (i + 1 === len) {
                    return v;
                  } else {
                    return v + ', ';
                  }
                })}
            </CustomDetailTypography>
          </CustomGrid>
          <Grid item xs={12}>
            <CustomTitleTypography>Publisher</CustomTitleTypography>
          </Grid>
          <CustomGrid item xs={12}>
            <CustomDetailTypography>
              {history._publisher === undefined ? '' : history._publisher.name}
            </CustomDetailTypography>
          </CustomGrid>
          <Grid item xs={12}>
            <CustomTitleTypography>Type</CustomTitleTypography>
          </Grid>
          <CustomGrid item xs={12}>
            <CustomDetailTypography>{history.type}</CustomDetailTypography>
          </CustomGrid>
          <Grid item xs={12}>
            <CustomTitleTypography>Rent</CustomTitleTypography>
          </Grid>
          <CustomGrid item xs={12}>
            <CustomDetailTypography>{history.rent}</CustomDetailTypography>
          </CustomGrid>
          <Grid item xs={12}>
            <CustomTitleTypography>Date</CustomTitleTypography>
          </Grid>
          <Grid item xs={12}>
            <CustomDetailTypography>{moment(history.date).format('YYYY.MM.DD hh:mm')}</CustomDetailTypography>
            <Divider variant="fullWidth" sx={{ color: 'black', marginBottom: '8px' }} />
          </Grid>

          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
            {history.type !== 'Rejected' && history.type !== 'Waiting for Approval' && (
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  size="small"
                  style={{ marginRight: '4px', textTransform: 'none' }}
                  startIcon={<ContentCopyIcon />}
                  onClick={() => {
                    //writeText : https, localhost에서만 동작
                    //navigator.clipboard.writeText(history.licenseCode);
                    // enqueueSnackbar('License code has been copied to the clipboard.', {
                    //   variant: 'success',
                    //   autoHideDuration: 3000,
                    // });
                    const val = document.getElementById('licenseCode');
                    val.select();
                    let copyValue = document.execCommand('copy');
                    if (copyValue) {
                      enqueueSnackbar('License code has been copied to the clipboard.', {
                        variant: 'success',
                        autoHideDuration: 3000,
                      });
                    }
                  }}
                >
                  Copy
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<DownloadIcon />}
                  style={{
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    formCSVLicense(history);
                  }}
                >
                  Download
                </Button>
              </Grid>
            )}

            <Grid item>
              {user.userData.role !== 'user' && history.type === 'Waiting for Approval' && (
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  startIcon={<CheckIcon />}
                  style={{ marginRight: '4px', textTransform: 'none' }}
                  onClick={clickAccept}
                >
                  Accept
                </Button>
              )}
              {user.userData.role !== 'user' && history.type === 'Waiting for Approval' && (
                <Button
                  color="error"
                  variant="outlined"
                  size="small"
                  startIcon={<DoNotDisturbIcon />}
                  style={{ marginRight: '4px', textTransform: 'none' }}
                  onClick={clickReject}
                >
                  Reject
                </Button>
              )}
              {user.userData.role !== 'user' && (
                <CustomButton
                  size="small"
                  onClick={() => {
                    setDeleteModalOpen(true);
                  }}
                >
                  <DeleteIcon />
                </CustomButton>
              )}
              {user.userData.role !== 'user' && (
                <CustomButton
                  color="error"
                  size="small"
                  onClick={() => {
                    if (!edit) {
                      setValue('customer', history._customer);
                    }
                    setEdit(!edit);
                  }}
                >
                  <EditIcon />
                </CustomButton>
              )}

              {history.type !== 'Waiting for Approval' && (
                <Button
                  color="error"
                  variant="contained"
                  size="small"
                  startIcon={<KeyIcon />}
                  style={{ textTransform: 'none' }}
                  onClick={modalHandleOpen}
                >
                  License
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Dialog open={open} aria-hidden={open ? 'false' : 'true'} onClose={modalHandleClose} maxWidth={'sm'}>
        <DialogTitle variant="h5" align="center">
          Reissue License
        </DialogTitle>
        <DialogContent align="center" style={{ minWidth: '400px' }}>
          <Typography variant="subtitle1">Enter new request code</Typography>
          <TextField fullWidth size="small" placeholder="Write request code." {...register('requestCode')} />
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            onClick={modalHandleClose}
            sx={{ textTransform: 'none' }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="small"
            variant="contained"
            color="error"
            onClick={handleSubmit(onSubmit)}
            sx={{ textTransform: 'none' }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={codeValidModalOpen}
        onClose={handleCodeValidModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ErrorOutlineRoundedIcon color="warning" style={{ fontSize: 64 }} />
          <Typography id="modal-modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '28px' }}>
            {formErrorMessage}
          </Typography>

          <Button variant="contained" color="error" size="small" onClick={handleCodeValidModalClose}>
            OK
          </Button>
        </Box>
      </Modal>

      <Modal
        open={deleteModalOpen}
        onClose={resultDeleteModalHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ErrorOutlineRoundedIcon color="warning" style={{ fontSize: 64 }} />
          <Typography id="modal-modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '28px' }}>
            Are you sure?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, pb: 2 }}>
            Once this
            <b style={{ color: 'red' }}> record </b>
            is deleted, it cannot be restored.
          </Typography>
          <div style={{ justifyContent: 'center' }}>
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              onClick={resultDeleteModalHandleClose}
              sx={{ textTransform: 'none', marginRight: '24px' }}
            >
              No
            </Button>
            <Button
              size="small"
              variant="contained"
              color="error"
              sx={{ textTransform: 'none' }}
              onClick={deleteCustomer}
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
      <SuccessModal
        messageTitle={modalMessage}
        open={openSuccessModal}
        handleClose={resultDeleteModalSuccessHandleClose}
      />
      <FailModal
        messageTitle={modalMessage}
        message={''}
        open={openFailModal}
        handleClose={resultDeleteModalHandleClose}
      />
    </>
  );
};

export default HistorySubSubComponent;
