import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Box, FormControl, Grid, InputLabel, Modal, NativeSelect, Typography, TextField } from '@mui/material';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { OPTION_SERVER, PRODUCT_SERVER, GROUPTYPE_SERVER } from '../../Config';
import { styled, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';

import SuccessModal from '../common/SuccessModal';
import FailModal from '../common/FailModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 460,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const theme = createTheme({
  main: 'rgba(0, 0, 0, 0.6)',
  orange: orange[500],
});

const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const CustomFormControl = styled(FormControl)({
  '& .MuiInputLabel-root': {
    color: theme.main,
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const ManageOptionsModal = (props) => {
  const [groupTypeList, setGroupTypeList] = useState();
  const [swList, setSwList] = useState();
  // result message modal
  const [modalMessageTitle, setModalMessageTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openFailModal, setOpenFailModal] = useState(false);

  const user = useSelector((state) => state.user);

  const resultModalHandleClose = (e) => {
    setModalMessage('');
    setOpenSuccessModal(false);
    setOpenFailModal(false);
  };
  const wordRegex = /^(?=.*\S)[A-Za-z0-9`~!@#$%^&*()-_=+.,?' ]*[A-Za-z0-9`~!@#$%^&*()-_=+.,?']$/u;
  const validationSchema = Yup.object().shape({
    groupType: Yup.string().required('This data is empty.'),
    product: Yup.string().required('This data is empty.'),
    optionType: Yup.string().required('This data is empty.'),
    optionName: Yup.string()
      .required('This data is empty.')
      .matches(wordRegex, {
        message: `Only English alphabets, numbers, and some special characters can be entered.
  Allowed special characters: \`~!@#$%^&*()-_=+.,?'`,
      }),
  });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      groupType: '',
      product: '',
      optionType: 'free',
      optionName: '',
      type: '',
    },
    resolver: yupResolver(validationSchema),
  });

  //add or edit product
  const onSubmit = async (data, e) => {
    setValue('type', props.details.type);

    if (props.details.type === 'Edit') {
      data['beforeOptionName'] = props.details.option.optionName;
    }

    axios.post(`${process.env.REACT_APP_SERVER}${OPTION_SERVER}`, data).then((res) => {
      if (res.data.err) {
        setModalMessageTitle('Failed to Update');
        setModalMessage(res.data.msg);
        setOpenFailModal(true);
        props.handleClose();
      } else {
        setModalMessageTitle('Updated Successfully');
        setOpenSuccessModal(true);
        props.handleClose();
      }
    });
  };

  useEffect(() => {
    if (props.open) {
      if (props.details !== undefined) {
        axios.get(`${process.env.REACT_APP_SERVER}${GROUPTYPE_SERVER}/type`).then((res) => {
          setGroupTypeList(res.data);
          let product = props.details.product;
          axios.get(`${process.env.REACT_APP_SERVER}${PRODUCT_SERVER}/${product.groupType}`).then((res) => {
            setSwList(res.data);
          });

          let option = props.details.option;
          setValue('groupType', props.details.product.groupType);
          setValue('product', product._id);

          if (props.details.type === 'Edit') {
            setValue('optionName', option.optionName);
            setValue('optionType', option.optionType);
          }
        });
      }
    } else {
      // setLicenseType('');
      reset();
    }
  }, [props.open]);

  useEffect(() => {
    if (props.open) {
      if (props.details !== undefined) {
        axios.get(`${process.env.REACT_APP_SERVER}${PRODUCT_SERVER}/${watch('groupType')}`).then((res) => {
          setSwList(res.data);
          if (res.data.length > 0) {
            setValue('product', res.data[0]._id);
          }
        });
      }
    }
  }, [watch('groupType')]);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" style={{ margin: 16, fontWeight: 600, fontSize: '28px' }}>
            {props.details && props.details.type} an Option
          </Typography>

          <Grid item container direction="row" justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={12}>
              <CustomFormControl type="text" variant="standard" style={{ width: '75%', height: '40px' }}>
                <InputLabel shrink htmlFor="groupType">
                  <span>
                    <b style={{ color: 'red' }}>* </b>
                    License Type
                  </span>
                </InputLabel>
                <NativeSelect
                  id="groupType"
                  fullWidth
                  size="small"
                  {...register('groupType')}
                  value={watch('groupType') || ''}
                >
                  {groupTypeList &&
                    groupTypeList.map((v, i) => {
                      return (
                        <option key={v._id} value={v._id}>
                          {v.groupTypeName}
                        </option>
                      );
                    })}
                </NativeSelect>
              </CustomFormControl>
            </Grid>
            <Grid item xs={12}>
              <CustomFormControl type="text" variant="standard" style={{ width: '75%', height: '40px' }}>
                <InputLabel shrink htmlFor="product">
                  <span>
                    <b style={{ color: 'red' }}>* </b>
                    Product
                  </span>
                </InputLabel>
                <NativeSelect
                  id="product"
                  fullWidth
                  size="small"
                  {...register('product')}
                  value={watch('product') || ''}
                >
                  {swList &&
                    swList.map((v, i) => {
                      return (
                        <option key={v._id} value={v._id}>
                          {v.name}
                        </option>
                      );
                    })}
                </NativeSelect>
              </CustomFormControl>
            </Grid>
            <Grid item xs={12}>
              <CustomFormControl type="text" variant="standard" style={{ width: '75%', height: '40px' }}>
                <InputLabel shrink htmlFor="optionType">
                  <span>
                    <b style={{ color: 'red' }}>* </b>
                    Option Type
                  </span>
                </InputLabel>
                <NativeSelect
                  id="optionType"
                  fullWidth
                  size="small"
                  {...register('optionType')}
                  value={watch('optionType') || ''}
                >
                  <option key={'free'} value={'free'}>
                    Free
                  </option>
                  <option key={'paid'} value={'paid'}>
                    Paid
                  </option>
                </NativeSelect>
              </CustomFormControl>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                variant="standard"
                label={
                  <span>
                    <b style={{ color: 'red' }}>* </b>
                    Option Name
                  </span>
                }
                autoComplete="optionName"
                inputProps={{ maxLength: 135 }}
                {...register('optionName')}
                value={watch('optionName') || ''}
                error={Boolean(errors.optionName)}
                helperText={errors.optionName?.message}
                size="small"
                style={{ width: '75%' }}
              />
            </Grid>

            <Grid item xs={12}>
              <div style={{ marginTop: '16px', justifyContent: 'center' }}>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  sx={{ textTransform: 'none', marginRight: '24px' }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="inherit"
                  onClick={props.handleClose}
                  sx={{ textTransform: 'none' }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <SuccessModal messageTitle={modalMessageTitle} open={openSuccessModal} handleClose={resultModalHandleClose} />
      <FailModal
        messageTitle={modalMessageTitle}
        message={modalMessage}
        open={openFailModal}
        handleClose={resultModalHandleClose}
      />
    </div>
  );
};

export default ManageOptionsModal;
