//SERVER ROUTES
export const USER_SERVER = '/api/user';
export const License_SERVER = '/api/license';
export const GROUP_SERVER = '/api/group';
export const GROUPTYPE_SERVER = '/api/grouptype';
export const PRODUCT_SERVER = '/api/product';
export const OPTION_SERVER = '/api/option';
export const HISTORY_SERVER = '/api/history';
export const EMAIL_SERVER = '/api/email';
export const DASHBOARD_SERVER = '/api/dashboard';
