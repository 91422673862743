import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  NativeSelect,
  Typography,
} from '@mui/material';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { styled, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { enqueueSnackbar } from 'notistack';
import GEDelphiModal from './GEDelphiModal';

import axios from 'axios';
import { GROUP_SERVER } from '../../Config';

const theme = createTheme({
  main: 'rgba(0, 0, 0, 0.6)',
  orange: orange[500],
});

const CustomFormControl = styled(FormControl)({
  '& .MuiInputLabel-root': {
    color: theme.main,
    padding: '4px',
  },
  '& .MuiNativeSelect-select': {
    padding: '6px',
    fontWeight: '600',
  },
  '& label.Mui-focused': {
    color: theme.orange,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.orange,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.orange,
    },
  },
});

const CustomButton = styled(Button)({
  textTransform: 'none',
});

function GEDelphiBox() {
  const delphiGroup = '5f7c046ce1d3e323c8b25980';
  const [groupList, setGroupList] = useState();
  const [groupLicenseCount, setGroupLicenseCount] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [open, setOpen] = useState(false);

  const [refresh, setRefresh] = useState(false);
  // const [addGroupName, setAddGroupName] = useState();
  const [changeLicenseType, setChangeLicenseType] = useState();

  //modal handler
  const modalHandleClose = (e) => {
    setOpen(false);
    setRefresh(true);
  };
  // refresh onClick
  const refreshOnclick = (e) => {
    setRefresh(true);
  };

  const handleListItemClick = (event, index, value) => {
    setSelectedIndex(index);
    setGroupLicenseCount(value.DelphiAvailableLicenseCount);
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}/GEGroup`).then((res) => {
      setGroupList(res.data);
    });
  }, []);

  useEffect(() => {
    // List DB로 부터 가져오기
    if (refresh) {
      axios.get(`${process.env.REACT_APP_SERVER}${GROUP_SERVER}/GEGroup`).then((res) => {
        setGroupList(res.data);
        if (selectedIndex !== undefined) {
          setGroupLicenseCount(res.data[selectedIndex].DelphiAvailableLicenseCount);
        }
      });

      enqueueSnackbar('GEGroup List Refresh Completed', {
        variant: 'success',
        autoHideDuration: 2500,
      });
    }
    setRefresh(false);
  }, [refresh]);

  return (
    <>
      <Card elevation={1} sx={{ padding: '12px' }}>
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item xs={12}>
            <Box
              p={1}
              style={{
                minHeight: '190px',
                whiteSpace: 'pre-wrap',
                backgroundColor: 'rgba(220,220,220,1.00)',
              }}
            >
              <Typography variant="h4" fontWeight={500} style={{ fontSize: '26px', padding: '12px' }}>
                GE Delphi License
              </Typography>
              <Divider />
              <Typography m={1} variant="subtitle1" fontSize={15}>{`GE Definium License. \n`}</Typography>
              <Typography
                m={1}
                variant="subtitle1"
                fontSize={15}
              >{`After increasing or decreasing the license quantity, Click Refresh button.`}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4} container justifyContent="flex-start">
            <Typography color={'rgba(127,127,127,1.00)'} style={{ paddingLeft: '20px' }}>
              <b style={{ color: 'red', fontSize: '24px' }}>{groupLicenseCount}</b> Copies
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} container justifyContent="flex-end">
            <CustomButton
              color="error"
              variant="contained"
              size="small"
              style={{ margin: '4px 0px 4px 4px', fontSize: '0.72rem' }}
              startIcon={<AutorenewIcon />}
              onClick={refreshOnclick}
            >
              Refresh
            </CustomButton>
            <CustomButton
              color="error"
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              style={{ margin: '4px 0px 4px 4px', fontSize: '0.72rem' }}
              disabled={selectedIndex === undefined}
              onClick={(e) => {
                setChangeLicenseType('Add');
                setOpen(true);
              }}
            >
              License
            </CustomButton>
            <CustomButton
              color="error"
              variant="contained"
              size="small"
              style={{ margin: '4px 0px 4px 4px', fontSize: '0.72rem' }}
              startIcon={<RemoveIcon />}
              disabled={selectedIndex === undefined}
              onClick={(e) => {
                setChangeLicenseType('Remove');
                setOpen(true);
              }}
            >
              License
            </CustomButton>
          </Grid>

          <Grid item xs={12} style={{ border: '1px solid rgba(197,197,197,1.00)' }}>
            <CustomFormControl fullWidth>
              <InputLabel variant="standard" htmlFor="gedelphi-licenses">
                Licenses
              </InputLabel>
              <NativeSelect
                value={delphiGroup}
                // onChange={handleChange}
                id="gedelphi-licenses"
                disabled
              >
                <option value={delphiGroup}>GE Delphi</option>
              </NativeSelect>
            </CustomFormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              border: '1px solid rgba(197,197,197,1.00)',
              height: '320px',
              overflow: 'hidden',
              overflowY: 'scroll',
            }}
          >
            {groupList && (
              <List id="gegroup-list" disablePadding>
                {groupList &&
                  groupList.map((v, i) => {
                    return (
                      <ListItemButton
                        divider
                        key={v._id}
                        selected={selectedIndex === i}
                        onClick={(event) => handleListItemClick(event, i, v)}
                      >
                        <ListItemText primary={i + 1 + 'ㅤㅤ' + v.name} />
                      </ListItemButton>
                    );
                  })}
              </List>
            )}
          </Grid>
        </Grid>
      </Card>
      <GEDelphiModal
        open={open}
        onClose={modalHandleClose}
        licenseType={changeLicenseType}
        groupList={groupList}
        selectedIndex={selectedIndex}
      />
    </>
  );
}

export default GEDelphiBox;
