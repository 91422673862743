import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import Auth from '../hoc/auth.js';
// pages for this product
import LicensePage from './views/License/LicensePage.js';
import LoginPage from './views/User/LoginPage.js';
import ProfilePage from './views/User/ProfilePage.js';
import ChangePWPage from './views/User/ChangePWPage.js';
import ManageMemberPage from './views/Membership/ManageMemberPage.js';
import RegisterPage from './views/Membership/RegisterPage.js';
import HistoryPage from './views/History/HistoryPage.js';
import OtherPage from './views/Other/OtherPage.js';
import Header from './views/Header/Header.js';
import SignupPage from './views/User/SignupPage.js';
import ProductManagePage from './views/Admin/ProductManagePage.js';
import SettingPage from './views/Admin/SettingPage.js';
import HistoryListPage from './views/Other/HistoryListPage';
import ApproveMemberPage from './views/Membership/ApproveMemberPage.js';
import DashboardPage from './views/Admin/DashboardPage.js';
import Footer from './views/Footer/Footer';

//Auth(page, option, adminRoute)
// option ->
//null   Anyone Can go inside
//true   only logged in user can go inside
//false  logged in user can't go inside
// adminRoute ->
// null Anyone
// true only super admin
// false admin, super admin

const AuthHeader = Auth(Header, true, null);
const AuthLoginPage = Auth(LoginPage, false, null);
const AuthProfilePage = Auth(ProfilePage, true, null);
const AuthManageMemberPage = Auth(ManageMemberPage, true, false);
const AuthRegisterPage = Auth(RegisterPage, true, false);
const AuthLicensePage = Auth(LicensePage, true, null);
const AuthHistoryPage = Auth(HistoryPage, true, null);
const AuthOtherPage = Auth(OtherPage, true, false);
const AuthSignupPage = Auth(SignupPage, null, false);
const AuthChangePWPage = Auth(ChangePWPage, true, null);
const AuthProductManagePage = Auth(ProductManagePage, true, true);
const AuthSettingPage = Auth(SettingPage, true, true);
const AuthHistoryListPage = Auth(HistoryListPage, true, false);
const AuthApproveMemberPage = Auth(ApproveMemberPage, true, false);
const AuthDashboardPage = Auth(DashboardPage, true, true);

function App() {
  const user = useSelector((state) => state.user);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        // TransitionComponent={Slide}
      >
        <Routes>
          <Route element={<AuthHeader />}>
            <Route path="/" element={<AuthHeader />} />
            <Route path="/license" element={<AuthLicensePage />} />
            <Route path="/register" element={<AuthRegisterPage />} />
            <Route path="/profile" element={<AuthProfilePage />} />
            <Route path="/changepw" element={<AuthChangePWPage />} />
            <Route path="/manageuser" element={<AuthManageMemberPage />} />
            <Route path="/history" element={<AuthHistoryPage />} />
            <Route path="/other" element={<AuthOtherPage />} />
            <Route path="/manageproduct" element={<AuthProductManagePage />} />
            <Route path="/setting" element={<AuthSettingPage />} />
            <Route path="/otherhistory" element={<AuthHistoryListPage />} />
            <Route path="/memberapprove" element={<AuthApproveMemberPage />} />
            <Route path="/dashboard" element={<AuthDashboardPage />} />
          </Route>
          {/* <Route path="/signup" element={<AuthSignupPage />} /> */}
          <Route path="/login" element={<AuthLoginPage />} />
        </Routes>

        {!user.userData.error && <Footer />}
      </SnackbarProvider>
    </Suspense>
  );
}

export default App;
